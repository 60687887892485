import React, {useState, useContext, useEffect} from 'react';
import Header from '../components/header/Header'
import {DataContext} from "../context/dataContext";
import EmptyJobCheck from "../helper/emptyJobCheck";
import axios from "axios";
import SettingsForImageToImage from "../components/SettingsForImage/SettingsForImageToImage";
import { useNavigate } from "react-router-dom";

const ImageToImage = () => {
    const navigate = useNavigate();
    const [amount, setAmount] = useState({});
    let imageToCustomize = localStorage.getItem("imageToCustomize")
    const {user} = useContext(DataContext)
    const [settings, setSettings] = useState({});
    const emptyJob = []

    const sendJobToSave = async () => {
        // eslint-disable-next-line no-undef
        const base64Canvas = canvas.toDataURL("image/jpeg")
        settings.init_images = [imageToCustomize]
        settings.mask = base64Canvas
        let dataToSend = {
            ...emptyJob,
            config: settings,
            status: 'ready',
            //should init
        }
        console.log("dataToSend", dataToSend)
        await axios.patch(`https://api.artfactory.live/job`, dataToSend, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                removeEmptyJobFromLocalStorage()
                console.log(res)
                EmptyJobCheck(user)
                navigate('/gallery')
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const sendJobWithPayments = async () => {
        let {last_name, first_name, email, phone, meta, uuid,} = user
        let dataToSend = {
                "merchant_id": "brushless",
                job: {
                    ...emptyJob, config: settings,
                    payment: {
                        "method": "bankcard_ccp",
                        "amount": amount.amount,
                        "currency": amount.currency,
                        "user": {
                            "user_uuid": uuid,
                            "fname": first_name,
                            "lname": last_name,
                            "address": meta.address,
                            "address2": meta.address2,
                            "city": meta.city,
                            "province": meta.state,
                            "country": meta.country,
                            "post_code": meta.zip,
                            "phone": phone,
                            "email": email
                        }
                    }
                }
            }

    await axios.post(`https://api.artfactory.live/payment`, dataToSend, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    })
        .then((res) => {
            removeEmptyJobFromLocalStorage()
            console.log(res)
            EmptyJobCheck(user)
            window.location.href = res.data.url
        })
        .catch((error) => {
            console.log(error)
        });
}

const removeEmptyJobFromLocalStorage = () => {
    let jobs = JSON.parse(localStorage.getItem("jobs"))
    let filteredJobs = jobs.filter(job => job.status !== 'init')
    localStorage.setItem("jobs", JSON.stringify(filteredJobs))
}

useEffect(() => {

    let dimensions = JSON.parse(localStorage.getItem('imageDimensions')) ?? {width: 512, height: 512}
    var canvas = document.getElementById('canvas');
    var ctx = canvas.getContext('2d');
    resize();
    var pos = { x: 0, y: 0 };
    window.addEventListener('resize', resize);
    canvas.addEventListener('mousemove', draw);
    canvas.addEventListener('mousedown', setPosition);
    canvas.addEventListener('mouseenter', setPosition);

    function setPosition(e) {
        pos.x = e.offsetX;
        pos.y = e.offsetY;

    }

    function resize() {
        ctx.canvas.width = dimensions.width;
        ctx.canvas.height = dimensions.height;
    }

    function draw(e) {
        if (e.buttons !== 1) return;
        ctx.beginPath(); // begin
        ctx.lineWidth = 25;
        ctx.lineCap = 'round';
        ctx.strokeStyle = '#fff';
        ctx.moveTo(pos.x, pos.y); // from
        setPosition(e);
        ctx.lineTo(pos.x, pos.y); // to
        ctx.stroke(); // draw it!
    }
    canvas.style.backgroundImage = `url(${imageToCustomize})`

}  ,[])


return (
    <div>
        <Header/>
        <div style={{display:'flex', justifyContent:'center'}} >
            <canvas style={{borderRadius:'15px'}} id="canvas">
            </canvas>
        </div>


        <SettingsForImageToImage callback={(value) => {setSettings(value)}} Pamount={(amount)=>{setAmount(amount)}}/>
        {/*<button onClick={() => {*/}
        {/*    sendJobWithPayments()*/}
        {/*}}>PAY*/}
        {/*</button>*/}
        <div style={{display:'flex', justifyContent:'center', width:'100%',marginBottom:'50px'}}>
        <button className='btn-action style-2' onClick={() => {
            sendJobToSave()
        }}>Save it for later
        </button>
        </div>
        {/*<Portfolio2 data={dataPortfolio}/>*/}
        {/*<Partners2 data={dataPartners}/>*/}
        {/*<FooterStyle2/>*/}
    </div>
);
}

export default ImageToImage;