import axios from "axios";

const getAllJobs = async (user, merge) => {
    try {
        const data = (await axios.get(`https://api.artfactory.live/jobs`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })).data
        if(typeof data !== 'object' || data === null) throw Error("Data is not an object")
        if (!!merge) {
            const mergeDatas = [...data,...merge]
            localStorage.setItem('jobs', JSON.stringify(mergeDatas))
            return mergeDatas
        }
        localStorage.setItem('jobs', JSON.stringify(data))
        return data
    } catch (error) {
        console.error(error)
        throw error
    }

}
export default getAllJobs