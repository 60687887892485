import React, {useContext, useState, useEffect} from 'react';
import Action from '../components/layouts/Action';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import {Languages} from "../languages/languages";
import {DataContext} from "../context/dataContext";
import axios from "axios";
import {country} from "../helper/country";

const Profile = () => {
    const {user, setUser, language} = useContext(DataContext)
    const [newData,setNewData] = useState(user)
    const [selected, setSelected] = useState('')

    const changeUserData = (e, credential, sub) => {
        setNewData((prev) => {
            if (sub) {
                if(!prev[sub]) prev[sub] = {}
                prev[sub][credential] = e.target.value;
            } else {
                prev[credential] = e.target.value;
            }
            return prev
        })
    }
useEffect(()=>{
    setNewData(user)
},[user])

const updateProfile = () => {
    axios.patch(`https://api.artfactory.live/profile`,{
        "first_name": newData?.first_name,
        "last_name": newData?.last_name,
        "phone": newData?.phone,
        "comments": newData?.comments,
        "meta": {
            "zip": newData?.meta?.zip,
            "city": newData?.meta?.city,
            "state": newData?.meta?.state,
            "address": newData?.meta?.address,
            "country": newData?.meta?.country,
            "address2": newData?.meta?.address2,
        },
    }, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    },)
        .then((res) => {
            setUser(res?.data)
            localStorage.setItem("userCredentials", JSON.stringify(res.data));
        })
        .catch((error) => {
            console.error(error)
        })
}

    return (
        <div className='page-contact'>
            <Header/>
            <section className="tf-section contact " style={{paddingTop: '25px', paddingBottom: '0'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div  className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000">
                                    <span> {Languages[language].profile}</span></h1>
                                <h5  className="sub-title mb-10" data-aos="fade-up"
                                    data-aos-duration="1000">{!!user ? `${user?.first_name ?? ''} ${user?.last_name ?? ''}` : "You are not logged in"}</h5>
                            </div>
                        </div>
                    </div>
                    {!!user && <div style={{placeContent: 'center'}} className="row">
                        <div className="col-md-6">
                            <div className="contact-left">
                                <ul>
                                    <li data-aos="fade-up">
                                        <div className="icon"><i className="fas fa-user"></i></div>
                                        <div className="info">
                                            <h5>{Languages[language].name}</h5>
                                            {selected !== "name" ?
                                                <p className="fs-18">{newData?.first_name} {newData?.last_name}</p> :
                                                <>
                                                    <input onChange={(e)=>{changeUserData(e,"first_name")}} style={{marginBottom: '5px'}} placeholder='First Name' className='form-control' defaultValue={newData?.first_name}/>
                                                    <input onChange={(e)=>{changeUserData(e,"last_name")}} className='form-control' placeholder='Last Name' defaultValue={newData?.last_name}/>
                                                </>}

                                        </div>
                                        <div onClick={() => {
                                            selected === 'name' ? setSelected('') : setSelected('name')
                                        }} className="icon gear"><i
                                            className={selected === 'name' ? "fas fa-save" : "fas fa-cog"}></i></div>
                                    </li>
                                    <li data-aos="fade-up">
                                        <div className="icon"><i className="fas fa-phone-alt"></i></div>
                                        <div className="info">
                                            <h5>{Languages[language].phone}</h5>
                                            {selected !== "phone" ? <p className="fs-18">{newData?.phone}</p> :
                                                <>
                                                    <input onChange={(e)=>{changeUserData(e,"phone")}} style={{marginBottom: '5px'}} placeholder='Phone' className='form-control' type='number' defaultValue={newData?.phone}/>

                                                </>}
                                        </div>
                                        <div onClick={() => {
                                            selected === 'phone' ? setSelected('') : setSelected('phone')
                                        }} className="icon gear"><i className={selected === 'phone' ? "fas fa-save" : "fas fa-cog"}></i></div>
                                    </li>
                                    <li data-aos="fade-up">
                                        <div className="icon"><i className="fas fa-envelope"></i></div>
                                        <div className="info">
                                            <h5>{Languages[language].email}</h5>
                                            {selected !== "email" ? <p className="fs-18">{newData?.email}</p> :
                                                <>
                                                    <input onChange={(e)=>{changeUserData(e,"email")}} style={{marginBottom: '5px'}} placeholder='Email' className='form-control' type='email' defaultValue={newData?.email}/>

                                                </>}
                                        </div>
                                        {/*<div onClick={() => {*/}
                                        {/*    selected === 'email' ? setSelected('') : setSelected('email')*/}
                                        {/*}} className="icon gear"><i*/}
                                        {/*    className={selected === 'email' ? "fas fa-save" : "fas fa-cog"}></i>*/}
                                        {/*</div>*/}
                                    </li>
                                    <li data-aos="fade-up">
                                        <div className="icon"><i className="fas fa-map-marker-alt"></i></div>
                                        <div className="info">
                                            <h5>{Languages[language].address}</h5>
                                            {selected !== "address" ? <>
                                                    <p className="fs-18"><strong>{Languages[language].address}: </strong> {newData?.meta?.address}</p>
                                                    <p className="fs-18"><strong>{Languages[language].address}2:</strong> {newData?.meta?.address2}</p>
                                                    <p className="fs-18"><strong>{Languages[language].city}:</strong> {newData?.meta?.city}</p>
                                                    <p className="fs-18"><strong>{Languages[language].country}:</strong> {newData?.meta?.country
                                                    && Object.entries((country)).map(([key, value]) => {
                                                        if (key === newData?.meta?.country) {
                                                            console.log(value)
                                                            return value
                                                        }})
                                                    }</p>
                                                    <p className="fs-18"><strong>{Languages[language].state}:</strong> {newData?.meta?.state}</p>
                                                    <p className="fs-18"><strong>{Languages[language].zip}:</strong> {newData?.meta?.zip}</p></> :
                                                <>
                                                    <input onChange={(e)=>{changeUserData(e,"address", 'meta')}} style={{marginBottom: '5px'}} className='form-control' placeholder='Address' defaultValue={newData?.meta?.address}/>
                                                    <input onChange={(e)=>{changeUserData(e,"address2", 'meta')}} style={{marginBottom: '5px'}} className='form-control' placeholder='Address2' defaultValue={newData?.meta?.address2}/>
                                                    <input onChange={(e)=>{changeUserData(e,"city", 'meta')}} style={{marginBottom: '5px'}} className='form-control' placeholder='City' defaultValue={newData?.meta?.city}/>
                                                    <select
                                                        defaultValue={country["HU"]}
                                                        style={{width: '100%',marginBottom:'5px'}} onChange={(e) => {
                                                        setNewData({...newData, meta : {...newData.meta, country:Object.entries(country).filter((x) => x[1] === e.target.value)[0][0]}})
                                                    }}
                                                            className="form-control mr-0">
                                                        {Object.values(country).map((x, i) => {
                                                            return <option value={x}>{x}</option>
                                                        })}
                                                    </select>
                                                    <input onChange={(e)=>{changeUserData(e,"state", 'meta')}} style={{marginBottom: '5px'}} className='form-control' placeholder='State' defaultValue={newData?.meta?.state}/>
                                                    <input onChange={(e)=>{changeUserData(e,"zip", 'meta')}} style={{marginBottom: '5px'}} className='form-control' placeholder='Zip' defaultValue={newData?.meta?.zip}/>

                                                </>}


                                        </div>
                                        <div onClick={() => {
                                            selected === 'address' ? setSelected('') : setSelected('address')
                                        }} className="icon gear"><i className={selected === 'address' ? "fas fa-save" : "fas fa-cog"}/></div>
                                    </li>
                                </ul>
                            </div>
                            <div style={{marginTop:'50px',width:'100%',display:'flex', justifyContent:'center'}}>
                                <button type="submit" onClick={(e) => {
                                    updateProfile()
                                }} className="btn-action style-2">
                                    <span>{Languages[language].submit}</span></button>
                            </div>

                        </div>

                    </div>}

                </div>

            </section>

            <Action/>
            <Footer/>
        </div>
    );
}

export default Profile;