import React, {useContext} from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import {Languages} from "../languages/languages";
import {DataContext} from "../context/dataContext";

const Terms = () => {
    const domain = window.location.hostname;
    const url = window.location.href;
    const {user, setUser, language} = useContext(DataContext)
    return (
        <div className='page-blog'>
            <Header />
            <div className="section-content section-terms">
                <div className="container">
                    {Languages[language].TERMS}

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Terms;