import img1 from '../images/icon/wallet.png';
import img2 from '../images/icon/shopping-cart.png';
import img3 from '../images/icon/folder.png';
import img4 from '../images/icon/money-bag.png';

const dataPortfolio = [
    {
        img: 'https://api.artfactory.live/image/1669364880275',
        step:'Step 01',
        title : 'Register',
        desc: 'Register an account with us and get amazing pictures',
    },
    {
        img: img2,
        step:'Step 02',
        title : 'Go to create page',
        desc: 'Go to create page and create your own images',
    },
    {
        img: img3,
        step:'Step 03',
        title : 'Write your magic',
        desc: 'Write your magic and the machine will do the rest',
    },
    {
        img: img4,
        step:'Step 04',
        title : 'Wait for the results',
        desc: 'Wait for the results and enjoy your new images',
    },
]

export default dataPortfolio;