import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import dataPortfolio from '../assets/fake-data/data-portfolio';
import About from '../components/layouts/About';
import Action from '../components/layouts/Action';
import Counter from '../components/layouts/Counter';
import Portfolio from '../components/layouts/Portfolio';
import Faq from "./Faq";
import SliderOne from "../components/slider/SliderOne";
import Speciality from "../components/layouts/Speciality";
import Technology from "../components/layouts/Technology";
import dataTechnology from "../assets/fake-data/data-technology";
import Partners from "../components/layouts/Partners";
import dataPartners from "../assets/fake-data/data-partners";
import dataTestimonials from "../assets/fake-data/data-testimonials";
import Testimonial from "../components/layouts/Testimonial";
import Testimonials2 from "../components/layouts/Testimonials2";
import img1 from "../assets/images/testimonial/testimonial-01.jpg";
import avt1 from "../assets/images/testimonial/avt-01.png";





const AboutUs = () => {
    return (
        <div className='about'>
            <Header/>

            <SliderOne/>
            <About/>
            <Counter/>
            <Portfolio data={dataPortfolio}/>


            <Testimonials2/>
            <Faq/>
            <Action/>
            <Footer/>
        </div>
    );
}

export default AboutUs;