import React,{useContext} from 'react';
import Register from '../../assets/icons/icon/register.svg'
import Create from '../../assets/icons/icon/create.svg'
import Wait from '../../assets/icons/icon/wait.svg'
import Write from '../../assets/icons/icon/write.svg'
import {DataContext} from "../../context/dataContext";
import { Link } from 'react-router-dom'
import {Languages} from "../../languages/languages";

const Portfolio = (props) => {
    const {language} = useContext(DataContext)
    const data = props.data;

    return (
        <section className="tf-section porfolio">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                        <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>{Languages[language].join}</span></h1>
                        <h5 style={{paddingTop:'90px'}} className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">{Languages[language].join_top_text}</h5>
                        <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">{Languages[language].become}</h3>
                    </div>
                </div>
            </div>
            <div className="row mt-61" style={{marginTop:'0'}}>

                    <div className="col-md-6">
                        <div className="icon-box bg-2" data-aos="fade-up">
                            <div className="icon">
                                <img style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%'}} src={Register} alt="artfactory" />
                            </div>
                            <div className="content">
                                <Link to='/register' className="h5">
                                    {Languages[language].register}
                                </Link>
                                <p className="fs-18">{Languages[language].register_text}</p>
                            </div>
                        </div>
                    </div>

                <div className="col-md-6">
                    <div className="icon-box bg-2" data-aos="fade-up">
                        <div className="icon">
                            <img style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%'}} src={Create} alt="artfactory" />
                        </div>
                        <div className="content">
                            <Link to='/create' className="h5">
                                {Languages[language].go_to_create_page}
                            </Link>
                            <p className="fs-18">{Languages[language].go_to_create_page_text}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="icon-box bg-2" data-aos="fade-up">
                        <div className="icon">
                            <img style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%'}} src={Write} alt="artfactory" />
                        </div>
                        <div className="content">
                            <Link to='/create' className="h5">
                                {Languages[language].write_your_magic}
                            </Link>
                            <p className="fs-18">{Languages[language].write_your_magic_text}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="icon-box bg-2" data-aos="fade-up">
                        <div className="icon">
                            <img style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%'}} src={Wait} alt="artfactory" />
                        </div>
                        <div className="content">
                            <Link to='/create' className="h5">
                                {Languages[language].wait_for_the_result}
                            </Link>
                            <p className="fs-18"> {Languages[language].wait_for_the_result_text}</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </section>
    );
}

export default Portfolio;