import React,{useContext} from 'react';
import {Languages} from "../languages/languages";
import FAQ3 from '../components/layouts/FAQ3';
import {DataContext} from "../context/dataContext";

const Faq = () => {
    const {language} = useContext(DataContext)
  return (
    <div className='home-3'>
        <FAQ3 data={Languages[language].FAQ} />
    </div>
  );
}

export default Faq;