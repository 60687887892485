import img1 from '../images/blog/blog-01.jpg'
import img2 from '../images/blog/blog-02.jpg'
import img3 from '../images/blog/blog-03.jpg'
import img4 from '../images/blog/blog-04.jpg'
import img5 from '../images/blog/blog-05.jpg'
import img6 from '../images/blog/blog-06.jpg'

import img7 from '../images/blog/blog-07.jpg'
import img8 from '../images/blog/blog-08.jpg'
import img9 from '../images/blog/blog-09.jpg'
import img10 from '../images/blog/blog-10.jpg'
import img11 from '../images/blog/blog-11.jpg'
import img12 from '../images/blog/blog-12.jpg'
import img13 from '../images/blog/blog-13.jpg'
import img14 from '../images/blog/blog-14.jpg'
import img15 from '../images/blog/blog-15.jpg'

const dataBlog = [
    {
        img: img1,
        category: 'NFT Trading',
        title: 'How to buy and sale nft in a easiest way'
    },
    {
        img: img2,
        category: 'NFT Trading',
        title: 'How to buy and sale nft in a easiest way'
    },
    {
        img: img3,
        category: 'NFT Trading',
        title: 'How to buy and sale nft in a easiest way'
    },
    {
        img: img1,
        category: 'NFT Trading',
        title: 'How to buy and sale nft in a easiest way'
    },
    {
        img: img4,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'February 07, 2022',
        title: 'When the musics over turn off the light',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex when the musics over turn off the light says jim morrison and doors. So close no matter how far whatis the best thing'
    },
    {
        img: img5,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'February 07, 2022',
        title: 'When the musics over turn off the light',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex when the musics over turn off the light says jim morrison and doors. So close no matter how far whatis the best thing'
    },
    {
        img: img6,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'February 07, 2022',
        title: 'When the musics over turn off the light',
        text: 'Oficia dese runt mollit anim id est labo met, conse ctetur adipi smod tempor inc ididunt ut labore et dolore qui inim veniam, quis nostrud ex when the musics over turn off the light says jim morrison and doors. So close no matter how far whatis the best thing'
    },
    {
        img: img4,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'Feb 07',
        title: 'Buy your NFT today',
    },
    {
        img: img5,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'Feb 07',
        title: 'How to make blockcahin',
    },
    {
        img: img6,
        category: 'Cryptography',
        user: 'John Doe',
        time: 'Feb 07',
        title: 'When the musics over ',
    },
    {
        img: img7,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img8,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img9,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img10,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img11,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img12,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img1,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img13,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img14,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
    {
        img: img15,
        category: 'NFT Trading',
        title: 'When the musics over turn off the light?'
    },
]

export default dataBlog;