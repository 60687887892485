import React, {useContext, useState, useEffect} from 'react';
import {DataContext} from "../../context/dataContext";
import axios from "axios";
import {w3cwebsocket as W3CWebSocket} from "websocket";
import EmptyJobCheck from "../../helper/emptyJobCheck";
import getAllJobs from "../../helper/getAllJob";
import {Link, useNavigate} from "react-router-dom";
import Pending from '../../assets/icons/icon/pending.svg'



const Montono2 = (props) => {
    const [pending, setPending] = useState(false)
    const navigate = useNavigate();
    const {user} = useContext(DataContext)
    const [data, setData] = useState(props.data)
    const [jobId, setJobId] = useState(false)
    const [paymentInformation, setPaymentInformation] = useState([])
    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        if (Array.isArray(data) && data.length > 0) {
            let pendingJobs = data.filter(job => job.status === "running")
            if (pendingJobs.length > 0) {
                setPending(true)

                async function getData() {
                    setData(await getAllJobs(user))
                }

                setTimeout(() => {
                    getData()
                }, 2000)
            } else {
                setPending(false)
            }
        }
    }, [data]);

    const paymentStatusCheck = async (jobId) => {
        let reqBody = {
            "merchant_id": "artfactorylive",
            "job_id": jobId
        }
        setPending(true)

        await axios.post('https://api.createart.live/payment', reqBody, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        },)
            .then((res) => {
                if (res?.data) {
                    if(res.data.status === "failure"){
                        async function getData() {
                            setData(await getAllJobs(user))
                        }
                        getData()
                    }
                    if (res.data.status === "success") {
                        startJob(jobId)
                        return;
                    }
                    console.log(res.data)
                    let filtered = paymentInformation.filter(job => job.id !== jobId)
                    setPaymentInformation([...filtered, {...res.data, id: jobId,}])
                    setPending(false)
                }
            })
            .catch((error) => {
                console.log(error)
                setPending(false)
            });
    }

    const startJob = async (id) => {
        setPending(true)
        await axios.get(`https://api.artfactory.live/start/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                async function getData() {
                    setData(await getAllJobs(user))
                }

                getData(res)
            })
            .catch((error) => {
                console.log(error)
            });
    }


    const pendingPaymentExist = (data && data?.filter(job => job?.status === "payment" || job?.status === "ready" || ((job?.config?.prompt||"").length > 3 && job.status==='init'))) ?? []
    const cancelledExist = (data && data?.filter(job => job?.status === "cancelled")) ?? []


    const sendJobToPaymentFromSave = async (job) => {

        let dataToSend = {
            "merchant_id": "test",
            job: {
                ...job
            }
        }

        await axios.post(`https://api.artfactory.live/pay`, dataToSend, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                console.log("WalletResponse",res)
                EmptyJobCheck(user)
                window.location.href = res.data.url
            })
            .catch((error) => {
                console.log(error)
            });
    }
    return (
        <section className="tf-section" id='portfolio'>
            {pendingPaymentExist.length > 0 &&
                <section className="tf-section action">
                <div className="container">
                    <div className="row">
                        <div className="col-12" style={{textAlign:"center"}}>
                            <h4> Pending payments</h4>
                                {data && data.map((job, index) => {
                                    if (job.status === "payment" || job.status === "ready") {
                                        let existInPayment = paymentInformation.find(jobs => jobs.id === job.id)
                                        return (
                                            <section  key={index} style={{padding:'0'}} className="tf-section action">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-12" style={{marginBottom:'22px'}}>

                                                            <div style={{paddingRight:'30px', paddingLeft:'50px', paddingTop:'15px', paddingBottom:'15px'}} className="action__body" data-aos="fade-up">
                                                                <div style={{display:'flex'}}>
                                                                <div className="icon" style={{alignSelf:'center'}}>
                                                                    <img style={{width:'100%',height:'100%',objectFit:'cover',borderRadius:'50%', maxHeight:'80px'}} src={Pending} alt="artfactory" />
                                                                </div>
                                                                <div style={{marginLeft:'45px',textAlignLast:'left'}} className="block-text" >
                                                                    <h3 style={{fontSize:'40px'}} className="mb-13">{job.config.prompt.length > 20 ?<>{job.config.prompt.slice(0,18)} .... </> : job.config.prompt}</h3>
                                                                    <p className="fs-21 mb-7">status: {job.status}</p>
                                                                    <p className="fs-21 mb-7">amount: {Math.floor(job.payment.amount)}  {job.payment.currency}</p>

                                                                </div>
                                                                </div>
                                                                {(job.status === "payment" || job.status === "ready") &&!pending &&
                                                                    <button className='btn-action style-2'
                                                                            onClick={() => paymentStatusCheck(job.id)}>{existInPayment && (existInPayment?.status ==='pending'||existInPayment?.status ==='ready') ? "Pending payment, please check again" : "Check"}</button>}
                                                                {pending && <div style={{position: 'absolute', top: '30%', left: '40%', zIndex: '10'}}
                                                                                 className="lds-ring">
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                    }
                                    if (job.status === "init" && job.config?.prompt.length > 3) {
                                        return (
                                            <div key={index} className='pending_arts'
                                                 style={{display: 'flex',position:'relative', flexDirection: 'column'}}>
                                                <span>input:{job.config.prompt}</span>
                                                <span>status:{job.status}</span>
                                                <span>amount:{job.payment.amount}</span>
                                                <span>currency:{job.payment.currency}</span>
                                                    <button className='btn-action style-2' onClick={() => {sendJobToPaymentFromSave(job)}}>Pay</button>
                                            </div>
                                        )
                                    }
                                })}

                            </div>
                        </div>
                    </div>

            </section>}



            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Gallery</span>
                            </h1>
                            {!!user ? <h5 className="sub-title mb-10" data-aos="fade-up"
                                          data-aos-duration="1000">{user?.first_name ?? ''} Gallery</h5>
                                :
                                <h5 className="sub-title mb-10" data-aos="fade-up"
                                    data-aos-duration="1000">You have to login</h5>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row mt-52">
                    {
                        Array.isArray(data) && data.sort((a, b) => b.id - a.id).map((data, index) => {
                            // debugger
                            const {status, id, config} = data

                            if (status === 'finished') {
                                return <div onClick={() => {
                                    navigate(`/viewer?${id}`)
                                }} key={index} className="col-xl-4 col-md-6">
                                    <div className="image-box" style={{overflow: 'visible'}} data-aos="fade-up"
                                         data-aos-duration="1000">
                                        <img style={{borderRadius: '20px'}} alt="brushless"
                                             src={`https://api.artfactory.live/image/${id}`}/>
                                        {/*<div style={{display:'flex', justifyContent:'center',flexDirection:'column', alignItems:'center'}} className="image-box__title">*/}

                                        {/*    <button style={{height:'20px',display:'flex', alignItems:'center', width:'250px', marginBottom:'7px', placeContent:'center'}} onClick={() => {*/}
                                        {/*        localStorage.setItem('imageDimensions', JSON.stringify({*/}
                                        {/*            width: config.width,*/}
                                        {/*            height: config.height*/}
                                        {/*        }))*/}
                                        {/*        imageToImage(`https://api.artfactory.live/image/${id}`)*/}
                                        {/*    }} className="btn-action style-2">*/}
                                        {/*        <span>Go to customize</span>*/}
                                        {/*    </button>*/}
                                        {/*    <button onClick={() => {*/}
                                        {/*        DeleteJob(id)*/}
                                        {/*    }} style={{height:'20px',display:'flex', alignItems:'center', width:'200px',marginBottom:'7px',placeContent:'center'}} className="btn-action style-2">*/}
                                        {/*        <span>Delete</span>*/}
                                        {/*    </button>*/}
                                        {/*    <button onClick={() => {*/}
                                        {/*        let randomNumber = Math.floor(Math.random() * 100000)*/}
                                        {/*        saveAs(`https://api.artfactory.live/image/${id}`, `image${randomNumber}.jpg`)*/}
                                        {/*    }} style={{height:'20px',display:'flex', alignItems:'center', width:'200px', placeContent:'center'}} className="btn-action style-2">*/}
                                        {/*        Download*/}
                                        {/*    </button>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            }
                            // if(status === 'init'){
                            //     return <div key={index} className="col-xl-4 col-md-6">
                            //         <div className="image-box" style={{overflow: 'visible'}} data-aos="fade-up"
                            //              data-aos-duration="1000">
                            //             <img style={{borderRadius: '20px'}} alt="brushless"
                            //                  src={`https://api.artfactory.live/image/${id}`}/>
                            //             <div className="image-box__title">
                            //                 <div>{config.prompt}</div>
                            //
                            //                 <button onClick={() => {
                            //                     DeleteJob(id)
                            //                 }} className="btn-action style-2">
                            //                     <span>Delete</span>
                            //                 </button>
                            //             </div>
                            //         </div>
                            //     </div>
                            // }
                            if (status === 'running' || status === 'queued') {
                                return <div style={{position: 'relative'}} key={index} className="col-xl-4 col-md-6">
                                    {pending && <div style={{position: 'absolute', top: '50%', left: '50%', zIndex: '10'}}
                                                     className="lds-ring">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>}
                                    <div className="image-box" style={{overflow: 'visible'}} data-aos="fade-up"
                                         data-aos-duration="1000">
                                        <img style={{borderRadius: '20px'}} alt="brushless"
                                             src='https://www.shutterstock.com/image-vector/vintage-old-black-sign-please-260nw-533155735.jpg'/>
                                        <div className="image-box__title">
                                            <div>{config.prompt}</div>
                                        </div>
                                    </div>
                                </div>
                            }

                        })
                    }
                </div>
            </div>

            {cancelledExist.length > 0 &&

                        <div style={{textAlign:"center", color:"grey"}}>
                            <h4>  Cancelled jobs</h4>
                                {data && data.map((job, index) => {
                                    if (job.status === "cancelled") {
                                        if (job.config.prompt.length < 3) return null
                                        return (
                                            <div style={{display:'flex',justifyContent:"center", color:'grey'}} key={index}>
                                                <span style={{marginRight:'15px'}}> input: {job.config.prompt.length > 20 ?<>{job.config.prompt.slice(0,21)} .... </> : job.config.prompt}</span>
                                                <span style={{marginRight:'15px'}}> status: {job.status}</span>
                                                <span style={{marginRight:'15px'}}> amount: {Math.floor(job.payment.amount)} {job.payment.currency}</span>

                                            </div>
                                        )
                                    }
                                    return null
                                })}
                        </div>
           }
        </section>

    );
}

export default Montono2;