import Home01 from './Home01';
import Home02 from './Home02';
import Home03 from './Home03';
import HomeOnePage from './HomeOnePage';
import AboutUs from './AboutUs';
import Collections from './Collections';
import Team from './Team';
import RoadMap from './RoadMap';
import OurMission from './OurMission';
import BlogList from './BlogList';
import BlogGrid from './BlogGrid';
import BlogSingle from './BlogSingle';
import Contact from './Contact';
import LoginRegister from "./LoginRegister";
import Profile from "./Profile";
import Create from "./Create";
import Gallery from "./Gallery";
import Faq from "./Faq";
import Terms from "./Terms";
import CibFaq from "./CibFaq";
import CibInfo from "./CibInfo";
import ImageToImage from "./ImageToImage";
import CreatePage from "./CreatePage";
import Inspiration from "./Inspiration";
import Viewer from "./Viewer";
import Thanks from "./Thanks";
import Privacy from "./Privacy";
import Refund from "./Refund";


const routes = [
    {path: '/', component: <AboutUs/>},
    {path: '/thanks', component: <Thanks/>},
    {path: '/profile', component: <Profile/>},
    {path: '/login', component: <LoginRegister/>},
    {path: '/register', component: <LoginRegister/>},
    {path: '/create', component: <CreatePage/>},
    {path: '/gallery', component: <Gallery/>},
    {path: '/terms', component: <Terms/>},
    {path: '/privacy', component: <Privacy/>},
    {path: '/refund', component: <Refund/>},
    {path: '/image_to_image', component: <ImageToImage/>},
    {path: '/inspiration', component: <Inspiration/>},
    {path: '/viewer', component: <Viewer/>},
    // {path: '*', component: <AboutUs/>},
    // { path: '/cib_faq', component: <CibFaq />},
    // { path: '/cib_info', component: <CibInfo />},
    // {path: '/home-02', component: <Home02/>},
    // {path: '/home-03', component: <Home03/>},
    //  { path: '/home-one-page', component: <HomeOnePage />},
    // {path: '/about', component: <AboutUs/>},
    // {path: '/collections', component: <Collections/>},
    // {path: '/team', component: <Team/>},
    // {path: '/road-map', component: <RoadMap/>},
    // {path: '/our-mission', component: <OurMission/>},
    // {path: '/develop', component: <CreatePage/>},
    // {path: '/blog-grid', component: <BlogGrid/>},
    // {path: '/blog-single', component: <BlogSingle/>},
    // {path: '/faq', component: <Faq/>},
    // {path: '/contact', component: <Contact/>},
]

export default routes;