import React, {useState, useContext, } from 'react';
import Header from '../components/header/Header'
import {DataContext} from "../context/dataContext";
import EmptyJobCheck from "../helper/emptyJobCheck";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SettingsForImage from "../components/SettingsForImage/SettingsForImage";

const Create = () => {
    const navigate = useNavigate();
    const [selectedImage, setSelectedImage] = useState(null);
    const [amount, setAmount] = useState({});
    const {user} = useContext(DataContext)
    const [settings, setSettings] = useState({});
    let emptyJob = JSON.parse(localStorage.getItem("jobs")).find(job => job.status === 'init') ?? EmptyJobCheck(user)

    const sendJobToSave = async () => {
        let dataToSend = {
            ...emptyJob, config: settings,
            status: 'ready',

        }

        await axios.patch(`https://api.artfactory.live/job`, dataToSend, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                removeEmptyJobFromLocalStorage()
                EmptyJobCheck(user)
                navigate('/gallery')
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const sendJobWithPayments = async () => {
        let {last_name, first_name, email, phone, meta, uuid,} = user
        let dataToSend = {
                "merchant_id": "brushless",
                job: {
                    ...emptyJob, config: settings,
                    payment: {
                        "method": "bankcard_ccp",
                        "amount": amount.amount,
                        "currency": amount.currency,
                        "user": {
                            "user_uuid": uuid,
                            "fname": first_name,
                            "lname": last_name,
                            "address": meta.address,
                            "address2": meta.address2,
                            "city": meta.city,
                            "province": meta.state,
                            "country": meta.country,
                            "post_code": meta.zip,
                            "phone": phone,
                            "email": email
                        }
                    }
                }
            }


    await axios.post(`https://api.artfactory.live/payment`, dataToSend, {
        headers: {
            'Authorization': `Bearer ${user.token}`
        }
    })
        .then((res) => {
            removeEmptyJobFromLocalStorage()
            console.log(res)
            EmptyJobCheck(user)
            window.location.href = res.data.url
        })
        .catch((error) => {
            console.log(error)
        });
}

const removeEmptyJobFromLocalStorage = () => {
    let jobs = JSON.parse(localStorage.getItem("jobs"))
    let filteredJobs = jobs.filter(job => job.status !== 'init')
    localStorage.setItem("jobs", JSON.stringify(filteredJobs))
}
// console.log(settings)

return (
    <div>
        <Header/>
        <div>
            {selectedImage && (
                <div>
                    <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                    <br />
                    <button onClick={()=>setSelectedImage(null)}>Remove</button>
                </div>
            )}
            <br />

            <br />
            <input
                type="file"
                name="myImage"
                onChange={(event) => {
                    console.log(event.target.files[0]);
                    setSelectedImage(event.target.files[0]);
                }}
            />
        </div>
        <SettingsForImage callback={(value) => {setSettings(value)}} Pamount={(amount)=>{setAmount(amount)}}/>
        {/*<button onClick={() => {*/}
        {/*    sendJobWithPayments()*/}
        {/*}}>PAY*/}
        {/*</button>*/}
        <div style={{display:'flex', justifyContent:'center', width:'100%',marginBottom:'50px'}}>
        <button className="btn-action style-2" onClick={() => {
            sendJobToSave()
        }}>Save it for later
        </button>
            </div>
        {/*<Portfolio2 data={dataPortfolio}/>*/}
        {/*<Partners2 data={dataPartners}/>*/}
        {/*<FooterStyle2/>*/}
    </div>
);
}

export default Create;