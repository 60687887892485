import React, {useState, useEffect, useContext} from 'react';
import {DataContext} from "../../context/dataContext";
import {Languages} from "../../languages/languages";
import {Link} from 'react-router-dom'
import RangeSlider from "../RangeSlider/RangeSlider";
import getAllJobs from "../../helper/getAllJob";

const CreateInputs = ({callback, template}) => {
    const {user, language} = useContext(DataContext)
    let rates = JSON.parse(localStorage.getItem('Rates'))
    const steps = Array.from(Array(150).keys()).map((i) => i + 1)
    const dimensions = [64, 128, 192, 256, 320, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960, 1024, 1088, 1152, 1216, 1280, 1344, 1408, 1472, 1536, 1600, 1664, 1728, 1792, 1856, 1920, 1984, 2048,]
    const CFG = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30,]
    const hughQualityArray = [0, 0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1,]
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [samplingSteps, setSamplingSteps] = useState(20);
    const [samplingMethod, setSamplingMethod] = useState('Euler a');
    const [width, setWidth] = useState(512);
    const [height, setHeight] = useState(512);
    const [CFG_Scale, setCFG_Scale] = useState(7);
    const [onePointFive, setOnePointFive] = useState(false)
    const [highQuality, setHighQuality] = useState(0)
    const [sampler_index, setSampler_index] = useState("Euler a")

    const dimensionCalculate = (dismension) => {
        switch (template) {
            case 'draft':
                return 512
            case "basic":
                setSamplingSteps(40)
                return 512
            case "standard":
                setSamplingSteps(30)
                setHighQuality(0.7)
                return 1024
            case "premium":
                setSamplingSteps(40)
                setHighQuality(0.7)
                return 1024
            case "supreme":
                setSamplingSteps(50)
                setHighQuality(0.7)
                return 1600
            case "custom":
                return dismension
        }
    }

    useEffect(() => {
        callback(
            {

                "steps": samplingSteps,
                "cfg_scale": CFG_Scale,
                "width": dimensionCalculate(width),
                "height": dimensionCalculate(height),
                "prompt": prompt,
                "negative_prompt": negativePrompt,
                "restore_faces": onePointFive,
                "type": "txt2img",
                "enable_hr": highQuality > 0,
                "firstphase_width": 0,
                "firstphase_height": 0,
                "denoising_strength": highQuality,
                "sampler_index": sampler_index

            }
        )
    }, [prompt, negativePrompt, samplingSteps, width, height, CFG_Scale, onePointFive, highQuality, sampler_index, template]);
    // console.log(template)
    return (
        <div className="blog-body">

            <div className="blog-box">
                <div style={{height: '200px'}} className="blog-img">
                    <img style={{width: '100%', height:'100%', objectFit:'cover'}}
                         src='https://api.artfactory.live/image/6'
                         alt="artfactory"/>
                </div>
                <div className="blog-content">

                    <div className="title fs-36">
                        {Languages[language].positive_top_text}
                    </div>
                    <p className="fs-18 text">  {Languages[language].positive_bottom_text}</p>
                    <div className="input-group" style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{width: '100%'}} className="form-outline">
                            <textarea
                                placeholder=""
                                onChange={(event) => {
                                    setPrompt(event.target.value)
                                }} type="search" id="form1" className="form-control" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-box">
                <div style={{height: '200px'}} className="blog-img">
                    <img style={{width: '100%', height:'100%', objectFit:'cover'}}
                         src='https://api.artfactory.live/image/2'
                         alt="artfactory"/>
                </div>
                <div className="blog-content">

                    <div className="title fs-36">
                        {Languages[language].avoid_top_text}
                    </div>
                    <p className="fs-18 text">  {Languages[language].avoid_bottom_text}</p>
                    <div className="input-group" style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{width: '100%'}} className="form-outline">
                            <input

                                onChange={(event) => {
                                    setNegativePrompt(event.target.value)
                                }} type="search" id="form1" className="form-control" placeholder=""/>

                        </div>
                    </div>
                </div>
            </div>
            <div className="blog-box">
                <div style={{height: '200px'}} className="blog-img">
                    <img style={{width: '100%', height:'100%', objectFit:'cover'}}
                         src='https://api.artfactory.live/image/1'
                         alt="artfactory"/>
                </div>
                <div className="blog-content">

                    <div className="title fs-36">
                        {Languages[language].artist_top_text}
                    </div>
                    <p className="fs-18 text">  {Languages[language].artist_bottom_text}</p>
                    <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly'}}>
                        <button className={`btn-action style-2 ${sampler_index === "Euler a" && "active"}`}
                                onClick={() => {
                                    setSampler_index('Euler a')
                                }}>Artist 1
                        </button>
                        <button className={`btn-action style-2 ${sampler_index === "Euler" && "active"}`}
                                onClick={() => {
                                    setSampler_index('Euler')
                                }}>Artist 2
                        </button>
                        <button className={`btn-action style-2 ${sampler_index === "DPM2 a" && "active"}`}
                                onClick={() => {
                                    setSampler_index('DPM2 a')
                                }}>Artist 3
                        </button>
                    </div>
                </div>
            </div>

            {/*CUSTOM ENABLES THE FOLLOWING*/}

            <div style={template === 'custom' ? null : {display: 'none'}}>
                <div className="blog-box">
                    {/*<div style={{height: '200px'}} className="blog-img">*/}
                    {/*    <img style={{width:'100%'}} src='https://dynaimage.cdn.cnn.com/cnn/c_fill,g_auto,w_1200,h_675,ar_16:9/https%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F201030094143-stock-rhodesian-ridgeback.jpg' alt="Monteno"/>*/}
                    {/*</div>*/}
                    <div className="blog-content">

                        <div className="title fs-36">
                            {Languages[language].size_top_text}
                        </div>
                        <p className="fs-18 text"> {Languages[language].size_bottom_text}</p>

                        <div className='input_wrapper'>
                            <span> {Languages[language].width}</span>
                            <RangeSlider
                                steps={dimensions}
                                indexOfDefault={7}
                                setDefault={dimensions.findIndex((i) => i === 512)}
                                callback={(x) => {
                                    setWidth(x.value)
                                }}/></div>

                        <div className='input_wrapper'>
                            <span> {Languages[language].height}</span>
                            <RangeSlider
                                steps={dimensions}
                                indexOfDefault={7}
                                setDefault={dimensions.findIndex((i) => i === 512)}
                                callback={(x) => {
                                    setHeight(x.value)
                                }}/>
                        </div>

                    </div>
                </div>

                <div className="blog-box">
                    {/*<div style={{height: '200px'}} className="blog-img">*/}
                    {/*    <img style={{width:'100%'}} src='https://dynaimage.cdn.cnn.com/cnn/c_fill,g_auto,w_1200,h_675,ar_16:9/https%3A%2F%2Fcdn.cnn.com%2Fcnnnext%2Fdam%2Fassets%2F201030094143-stock-rhodesian-ridgeback.jpg' alt="Monteno"/>*/}
                    {/*</div>*/}
                    <div className="blog-content">

                        <div className="title fs-36">
                            {Languages[language].high_top_text}
                        </div>
                        <p className="fs-18 text"> {Languages[language].high_bottom_text}</p>
                        <div className='input_wrapper'>
                            <span> {Languages[language].high_input_label}</span>
                            <RangeSlider
                                steps={hughQualityArray}
                                indexOfDefault={0}
                                setDefault={0}
                                callback={(x) => {
                                    setHighQuality(x.value)
                                }}/>
                        </div>
                    </div>
                </div>

                <div className="blog-box">
                    <div style={{height: '200px'}} className="blog-img">
                        <img style={{width: '100%', height:'100%', objectFit:'cover'}}
                             src='https://api.artfactory.live/image/3'
                             alt="artfactory"/>
                    </div>
                    <div className="blog-content">

                        <div className="title fs-36">
                            {Languages[language].steps_top_text}
                        </div>
                        <p className="fs-18 text"> {Languages[language].steps_bottom_text}</p>

                        <div className='input_wrapper'>
                            <span> {Languages[language].steps_input_label}</span>
                            <RangeSlider
                                steps={steps}
                                setDefault={steps.findIndex((i) => i === 20)}
                                indexOfDefault={4}
                                callback={(x) => {
                                    setSamplingSteps(x.value)
                                }}/>
                        </div>

                    </div>
                </div>

                <div className="blog-box">
                    <div style={{height: '200px'}} className="blog-img">
                        <img style={{width: '100%', height:'100%', objectFit:'cover'}}
                             src='https://api.artfactory.live/image/5'
                             alt="artfactory"/>
                    </div>
                    <div className="blog-content">

                        <div className="title fs-36">
                            {Languages[language].artist_freedom_top_text}
                        </div>
                        <p className="fs-18 text"> {Languages[language].artist_freedom_bottom_text}</p>
                        <div className='input_wrapper'>
                            <span> {Languages[language].artist_freedom_input_label}</span>
                            <RangeSlider
                                steps={CFG}
                                indexOfDefault={12}
                                setDefault={CFG.findIndex((i) => i === 7)}
                                callback={(x) => {
                                    setCFG_Scale(x.value)
                                }}/>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default CreateInputs;