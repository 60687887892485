import axios from "axios";

const getCurrentRate = async (user) => {
    try {
        const data = (await axios.get(`https://api.artfactory.live/rates`, )).data
        localStorage.setItem('Rates', JSON.stringify(data))
    } catch (error) {
        console.error(error)
        throw error
    }

}
export default getCurrentRate