import React,{useContext} from 'react';
import { Link } from 'react-router-dom'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Action from '../components/layouts/Action';
import img from '../assets/images/items/group-ntf-03.png'
import {DataContext} from "../context/dataContext";
import {Languages} from "../languages/languages";

const Inspiration = () => {

    const {language} = useContext(DataContext)
    return (
        <div className='mission'>
            <Header />

            <section className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div style={{textAlign:'center'}} className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                                 data-aos-easing="ease-out-cubic"
                                 data-aos-duration="2000">
                                <img src='https://api.artfactory.live/image/1669384096716' alt="artfactory" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].steps}: 50</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].seed}: 57193</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].width}: 512</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].height}: 768</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].artist}: Artist 2</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].template} : Custom</h5>

                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">
                                    {Languages[language].input} : <br></br>
                                    a portrait of an old coal miner in 19th century, beautiful painting with highly detailed face by greg rutkowski and magali villanueve</p>
                                <Link to="/create" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">{Languages[language].go_to_create}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section missions">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].steps}: 30</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].seed}: 444127948</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].width}: 512</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].height}: 768</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].artist}: Artist 1</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].template} : Custom</h5>

                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">
                                    {Languages[language].input} : <br></br>
                                    high resolution concept art of an apartment living room overlooking a large futuristic city with floor to ceiling windows and mid century modern furniture and a sad clown sitting on the couch cinematic lighting cgsociety</p>
                                <Link to="/create" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">{Languages[language].go_to_create}</Link>
                            </div>
                        </div>
                        <div className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-right"
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">
                                <img src='https://api.artfactory.live/image/1669384648139' alt="art" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div style={{textAlign:'center'}} className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                                 data-aos-easing="ease-out-cubic"
                                 data-aos-duration="2000">
                                <img src='https://api.artfactory.live/image/1669385193639' alt="artfactory" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].steps}: 60</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].seed}: 16</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].width}: 512</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].height}: 768</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].artist}: Artist 1</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].template} : Custom</h5>

                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">
                                    {Languages[language].input} : <br></br>
                                    strong warrior princess| centered| key visual| intricate| highly detailed| breathtaking beauty| precise lineart| vibrant| comprehensive cinematic| Carne Griffiths| Conrad Roset</p>
                                <Link to="/create" className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">{Languages[language].go_to_create}</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*<RoadMap data={dataRoadMap} />*/}

            <Action />
            <Footer />
        </div>
    );
}

export default Inspiration;