import React, { useState,useEffect } from 'react'
import './RangeSlider.scss'

export default function RangeSlider({ callback, steps, indexOfDefault, setDefault }) {

    const [sliderCurrentValue, setSliderCurrentValue] = useState(indexOfDefault)
    const breakpoints = steps
    useEffect(() => {
        setSliderCurrentValue(setDefault)
    }, [setDefault])
    return (
        <>
            <div style={{display:'flex',}}>
            <input className="range-style"
                   onChange={(e) => {
                       callback({ index: sliderCurrentValue, value: breakpoints[Number(e.target.value)] })
                       setSliderCurrentValue(Number(e.target.value))
                   }}
                   type="range" min="0"

                   value={sliderCurrentValue}
                   max={breakpoints.length - 1}
                   step="1" />

            <div className="output">{breakpoints[sliderCurrentValue]}</div>
            </div>
        </>
    )
}