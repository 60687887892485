import React, {useState, useEffect, useContext} from 'react';
import {Languages} from "../languages/languages";
import axios from "axios";
import Header from '../components/header/Header'
import {DataContext} from "../context/dataContext";
import {Link} from "react-router-dom";
import getAllJobs from "../helper/getAllJob";
import getCurrentRate from "../helper/getCurrentRate";
import EmptyJobCheck from "../helper/emptyJobCheck";
import {useNavigate} from "react-router-dom";
import {country} from "../helper/country";


const LoginRegister = () => {
    let [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    const entryPoint = window.location.pathname.split("/")
    const {user, setUser, language} = useContext(DataContext)
    const [login, setLogin] = useState(entryPoint[1] === "login")
    const [pending, setPending] = useState(false)
    const [info, setInfo] = useState('')
    const [registerOrLoginCredentials, setRegisterOrLoginCredentials] = useState({
        "password": '',
        "password2": '',
        "email": '',
        "first_name": '',
        "last_name": '',
        "phone": '',
        "comments": '',
        "meta": {
            "zip": '', "city": '', "state": '', "address": '', "country": '', "address2": '',
        },
    })

    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, [width]);


    const loginAction = async (e) => {
        e.preventDefault()
        if(registerOrLoginCredentials.email === '' || registerOrLoginCredentials.password === '') return setInfo('Please fill in all fields')
        if(registerOrLoginCredentials.email.length < 3 || registerOrLoginCredentials.password.length < 3) return setInfo('Please fill in all fields')
        setPending(true)
        await axios.post(`https://api.artfactory.live/login`, {
            email: registerOrLoginCredentials.email, password: registerOrLoginCredentials.password
        })
            .then((response) => {
                setPending(false)
                getCurrentRate()
                getAllJobs(response?.data)
                setUser(response?.data)
                localStorage.setItem("userCredentials", JSON.stringify(response.data));
    
            })
            .catch((error) => {
                setPending(false)
                console.log(error)
                if(error?.response?.data?.error?.message){
                    setInfo(error?.response?.data?.error?.message)
                }else{
                    setInfo('Something went wrong')
                }

            });
    }

    const registerAction = async (e) => {
        e.preventDefault()
        const {password, password2, email, first_name, last_name, phone, meta} = registerOrLoginCredentials
        if (email.length < 4) {
            return setInfo('Please enter an email address or user name.');
        }
        if (password.length < 4) {
            return setInfo('Password must be at least 4 characters')
        }
        if (password !== password2) {
            return setInfo("Password is not correct")
        }
        if (first_name.length < 2 || last_name.length < 2) {
            return setInfo("Name is too short")
        }
        if (meta.zip.length < 2 || meta.city.length < 2 || meta.state.length < 2 || meta.address.length < 2 || meta.country.length < 1) {
            return setInfo("Please enter a valid address")
        }
        setPending(true)
        await axios.post(`https://api.artfactory.live/register`, {
            password, email, first_name, last_name, phone, meta
        })
            .then((response) => {
                localStorage.setItem('jobs', JSON.stringify([]))
                getCurrentRate()
                setUser(response?.data)
                localStorage.setItem("userCredentials", JSON.stringify(response.data));
                setPending(false)
                EmptyJobCheck(response.data)
         
            })
            .catch((error) => {
                setPending(false)
                if(error?.response?.data?.error?.message){
                    setInfo(error?.response?.data?.error?.message)
                }else{
                   setInfo('Something went wrong')
                }

            });
    }


    //clear inputs
    useEffect(() => {
        setRegisterOrLoginCredentials({
            "password": '', "password2": '', "email": '', "first_name": '', "last_name": '', "phone": '',

            "meta": {
                "zip": '', "city": '', "state": '', "address": '', "country": '', "address2": '',
            },
        })
    }, [login])

    //clear info
    useEffect(() => {
        const timer = setTimeout(() => {
            setInfo('')
        }, 4000);
        return () => clearTimeout(timer);
    }, [info])

    return (<div className='page-contact'>
        <Header/>
        {!user ? <section className="tf-section login">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="block-text center">
                                <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000">
                                    <span>{login ? `${Languages[language].login}` : `${Languages[language].register}`}</span></h1>
                                <h5 className="sub-title mb-10" data-aos="fade-up"
                                    data-aos-duration="1000">{login ? `${Languages[language].login}` : `${Languages[language].register}`}</h5>

                            </div>
                        </div>
                    </div>
                    <div className="row" style={{placeContent: 'center', marginRight: '0'}}>
                        <div className="col-md-6">
                            <button onClick={() => {
                                setLogin(x => !x)
                            }} type="submit" className="btn-action style-2"
                                    style={{width: '100%', paddingTop: '5px', paddingBlock: '5px'}}><span>
                                {!login ? `${Languages[language].login}` : `${Languages[language].register}`} </span>
                            </button>
                            <form className="contact__form" style={{textAlign: 'center', paddingBottom: '200px'}}>
                                <div className="form-group">
                                    <input value={registerOrLoginCredentials.email} onChange={(e) => {
                                        setRegisterOrLoginCredentials((prev) => {
                                            return {...prev, email: e.target.value}
                                        })
                                    }} className="form-control" type="text" placeholder="Username/Email" required/>
                                    <input value={registerOrLoginCredentials.password} onChange={(e) => {
                                        setRegisterOrLoginCredentials((prev) => {
                                            return {...prev, password: e.target.value}
                                        })
                                    }} type="password" className="form-control mr-0" placeholder="password"
                                           required/>
                                    {!login && <>


                                        <input value={registerOrLoginCredentials.password2} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, password2: e.target.value}
                                            })
                                        }} type="password" className="form-control mr-0" placeholder="password again"
                                               required/>
                                        <input value={registerOrLoginCredentials.first_name} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, first_name: e.target.value}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="First name"
                                               required/>
                                        <input value={registerOrLoginCredentials.last_name} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, last_name: e.target.value}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="Last name"
                                               required/>
                                        <input value={registerOrLoginCredentials.phone} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, phone: e.target.value}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="Phone" required/>

                                        <input value={registerOrLoginCredentials.meta.zip} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, meta: {...prev.meta, zip: e.target.value}}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="Zip" required/>
                                        <input value={registerOrLoginCredentials.meta.city} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, meta: {...prev.meta, city: e.target.value}}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="City" required/>
                                        <input value={registerOrLoginCredentials.meta.state} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, meta: {...prev.meta, state: e.target.value}}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="State" required/>
                                        <input value={registerOrLoginCredentials.meta.address} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, meta: {...prev.meta, address: e.target.value}}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="Address" required/>

                                        <input value={registerOrLoginCredentials.meta.address2} onChange={(e) => {
                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev, meta: {...prev.meta, address2: e.target.value}}
                                            })
                                        }} type="text" className="form-control mr-0" placeholder="Address2" required/>

                                        <select style={{width: '70%'}} onChange={(e) => {

                                            setRegisterOrLoginCredentials((prev) => {
                                                return {...prev,
                                                    meta: {
                                                        ...prev.meta,
                                                        country: Object.entries(country).filter((x) => x[1] === e.target.value)[0][0]
                                                    }
                                                }
                                            })
                                        }}
                                                className="form-control mr-0">
                                            {Object.values(country).map((x, i) => {
                                                return <option value={x}>{x}</option>
                                            })}
                                        </select>

                                    </>}
                                </div>
                                {info.length > 2 &&
                                    <h3 style={{color: 'red'}} className="title mb-28 aos-init aos-animate"
                                        data-aos="fade-up" data-aos-duration="1000">{info}</h3>}

                                <button type="submit" onClick={(e) => {
                                    login ? loginAction(e) : registerAction(e)
                                }} className="btn-action style-2">
                                    <span>{login ? "Login" : "Register"} </span></button>
                            </form>
                        </div>
                    </div>

                </div>

            </section> :
            <div className="row" style={{marginRight: '0'}}>
                <div className="col-12">
                    <div className="block-text center">
                        <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000">
                            <span>You are Logged in</span></h1>
                        <h5 style={{paddingTop: '170px'}} className="sub-title mb-10" data-aos="fade-up"
                            data-aos-duration="1000">You are logged in</h5>
                        <div className='logged_in_first'>
                            <Link to={'/gallery'} className="btn-action style-2">
                                <span>My Gallery </span></Link>
                            <Link to={'/create'} className="btn-action style-2">
                                <span> Make Art </span></Link>
                        </div>
                    </div>

                </div>

            </div>
        }


        {/*<Footer />*/}
    </div>);
}

export default LoginRegister;