import React,{useContext} from 'react';
import CountUp from 'react-countup';
import {Link} from "react-router-dom";
import {DataContext} from "../../context/dataContext";

const Counter = () => {
    const {user} = useContext(DataContext);
    return (
        <section className="tf-section counter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="counter__body" data-aos="fade-down" data-aos-duration="1000">
                            <div className="counter">
                                <div className="number-counter">
                                    <CountUp end={152} />
                                </div>
                                <h5 className="title">Generated pictures</h5>
                            </div>
                            <Link to={!!user ? "/create" : '/login'} className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">Generate picture</Link>
                            <div className="counter">
                                <div className="number-counter">
                                <CountUp end={78} />
                                </div>
                                <h5 className="title">Current users</h5>
                            </div>
                            {/*<div className="counter">*/}
                            {/*    <div className="number-counter">*/}
                            {/*    <CountUp end={10} />B*/}
                            {/*    </div>*/}
                            {/*    <h5 className="title">Yearly Trading</h5>    */}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Counter;