import React from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Action from '../components/layouts/Action';
import {DataContext} from "../context/dataContext";
import img from '../assets/images/items/group-ntf-03.png'
import axios from "axios";
import {useNavigate} from "react-router-dom";
import { saveAs } from 'file-saver'
import {Languages} from "../languages/languages";


const Viewer = () => {
    const navigate = useNavigate();
    const {user, language} = React.useContext(DataContext)
    const url = window.location.href;
    const imageId = url.split('?').pop();
    const imageData = localStorage.getItem('jobs');
    const jobs = imageData !== undefined && JSON.parse(imageData);
    const findJobById = jobs.find(job => job.id === imageId);
    console.log(findJobById);

    const DeleteJob = async (id) => {
        await axios.delete(`https://api.artfactory.live/job/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                navigate('/gallery')
            })
            .catch((error) => {
                console.log(error)
            });
    }
    const imageToImage = (imageURL) => {
        const toDataURL = url => fetch(url)
            .then(response => response.blob())
            .then(blob => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.onloadend = () => resolve(reader.result)
                reader.onerror = reject
                reader.readAsDataURL(blob)
            }))
        toDataURL(imageURL)
            .then(dataUrl => {
                localStorage.setItem("imageToCustomize", dataUrl)
                navigate('/image_to_image')
            })
    }

    return (
        <div className='mission'>
            <Header />

            <section style={{paddingTop:'20px'}} className="tf-section visions">
                <div className="container">
                    <div className="row reverse">
                        <div style={{textAlign:'center'}} className="col-xl-7 col-md-12">
                            <div className="group-image" data-aos="flip-left"
                                 data-aos-easing="ease-out-cubic"
                                 data-aos-duration="2000">
                                <img style={{maxWidth:'700px'}} src={`https://api.artfactory.live/image/${imageId}`} alt="artfactory" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-12">
                            <div className="block-text pt-12">
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].steps}: {findJobById?.config?.steps ?? 0}</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].seed}: {findJobById?.meta?.info?.seed ?? 0}</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].width}: {findJobById?.config?.width ?? 0}</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].height}: {findJobById?.config?.height ?? 0}</h5>
                                <h5 className="title mb-37" data-aos="fade-up" data-aos-duration="1000">{Languages[language].artist}: {findJobById?.config?.sampler_index ?? 0}</h5>


                                <p className="fs-18 mb-41" data-aos="fade-up" data-aos-duration="1000">
                                    {Languages[language].input} : <br></br>
                                    {findJobById?.config?.prompt ?? 0}</p>
                                <div style={{display:'flex', flexDirection: 'column'}}>
                                    {/*<button style={{maxWidth:'280px', marginBottom:'9px'}} onClick={() => {*/}
                                    {/*    localStorage.setItem('imageDimensions', JSON.stringify({*/}
                                    {/*        width: findJobById?.config?.width,*/}
                                    {/*        height: findJobById?.config?.height*/}
                                    {/*    }))*/}
                                    {/*    imageToImage(`https://api.artfactory.live/image/${imageId}`)*/}
                                    {/*}} className="btn-action style-2">*/}
                                    {/*    <span>{Languages[language].go_to_customize}</span>*/}
                                    {/*</button>*/}
                                <button onClick={() => {
                                    DeleteJob(imageId)
                                }}  className="btn-action style-2" style={{maxWidth:'280px', marginBottom:'9px'}}  data-aos="fade-up" data-aos-duration="1200">
                                    <span>  {Languages[language].delete}</span>
                                </button>
                                <button style={{maxWidth:'280px', marginBottom:'7px'}} onClick={() => {
                                    let randomNumber = Math.floor(Math.random() * 100000)
                                    saveAs(`https://api.artfactory.live/image/${imageId}`, `image${randomNumber}.jpg`)
                                }} className="btn-action style-2"  data-aos="fade-up" data-aos-duration="1200">
                                    {Languages[language].download}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            {/*<RoadMap data={dataRoadMap} />*/}

            <Action />
            <Footer />
        </div>
    );
}

export default Viewer;