const menus = [
    // {
    //     id: 1,
    //     name: 'Home',
    //     auth: false,
    //     links: '#',
    //     namesub: [
    //         {
    //             id: 1,
    //             sub: 'Home 01',
    //             links: '/'
    //         },
    //         {
    //             id: 2,
    //             sub: 'Home 02',
    //             links: '/home-02'
    //         },
    //         {
    //             id: 3,
    //             sub: 'Home 03',
    //             links: '/home-03'
    //         },
    //         {
    //             id: 4,
    //             sub: 'Home One Page',
    //             links: '/home-one-page/'
    //         },
    //     ]
    // },
    // {
    //     id: 3,
    //     auth: false,
    //     name: 'Artists',
    //     links: '/artists'
    // },
    {
        id: 4,
        auth: false,
        name: {
            en: 'Inspiration',
            fr: 'Inspiration',
            am: 'Ոգեշնչում',
        },
        links: '/inspiration'
    },

    {
        id: 5,
        auth: true,
        name: {
            en: 'Create',
            fr: 'Créer',
            am: 'Ստեղծել',
        },
        links: '/create',

    },
    {
        id: 7,
        auth: true,
        name: {
            en: 'Gallery',
            fr: 'Galerie',
            am: 'Պատկերասրահ',
        },
        links: '/gallery'
    },
    {
        id: 6,
        auth: true,
        name: {
            en: 'Profile',
            fr: 'Profil',
            am: 'Անձնագիր',
        },
        links: '/profile',
    },
    {
        id: 8,
        auth: false,
        name: {
            en: 'Login',
            fr: 'Connexion',
            am: 'Մուտք գործել',
        },
        links: '/login',
    },
    {
        id: 9,
        auth: false,
        name: {
            en: 'Register',
            fr: 'S\'inscrire',
            am: 'Գրանցվել',
        },
        links: '/register',
    },

]

export default menus;