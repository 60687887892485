import React, {useState, useEffect, useContext} from 'react';
import {DataContext} from "../../context/dataContext";
import {Languages} from "../../languages/languages";

const Sidebar = ({callback, dataFromInputs}) => {
    const {language} = useContext(DataContext)
    const [template, setTemplate] = useState('draft');
    const [seed, setSeed] = useState('-1');
    let rates = JSON.parse(localStorage.getItem('Rates'))
    useEffect(() => {

        callback({
            template: template,
            seed: seed,
            price: calculatePrice(),
            country: rates.country,
            currency: rates.rates
        })
    }, [template, dataFromInputs]);

    const calculatePrice = () => {
        let amount = Math.round(Math.max(
            25,
            (dataFromInputs.width || 512) *
            (dataFromInputs.height || 512) *
            (dataFromInputs.steps || 20) / 209715.2
        ))
        if (amount > 1000) return 1000
        switch (template) {
            case 'draft':
                return 25
            case "basic":
                return 50
            case "standard":
                return 100
            case "premium":
                return 200
            case "supreme":
                return 300
            case "custom":
                return amount
        }
    }
    const calculatePriceInCurrency = (eurPrice) => {
        let country = rates?.country
        if (country === undefined) return <>{eurPrice} EUR</>
        switch (country) {
            case 'HU':
                return <>{Math.round(eurPrice * rates?.rates.HUF ?? 420)} HUF</>
            case 'US':
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD </>
            case 'AU':
                return <>{Math.round(eurPrice * rates?.rates.AUD ?? 1.6)} AUD</>
            case 'GB':
                return <>{Math.round(eurPrice * rates?.rates.GBP ?? 0.9)} GBP</>
            case 'NZ':
                return <>{Math.round(eurPrice * rates?.rates.NZD ?? 1.7)}NZD</>
            case 'ZA':
                return <>{Math.round(eurPrice * rates?.rates.ZAR ?? 19)}ZAR</>
            case 'CA':
                return <>{Math.round(eurPrice * rates?.rates.CAD ?? 1.4)} CAD</>
                break;
            default:
                return <>{Math.round(eurPrice * rates?.rates.USD ?? 1)} USD</>
        }
    }
    calculatePriceInCurrency()
    return (
        <div className="side-bar">
            <div className="side-bar__box">
                <h6 className="title"> {Languages[language].starting_number}</h6>
                <p>{Languages[language].starting_number_bottom_text}</p>
                <div className="side-bar__box__input">
                    <input className="form-control" onChange={(e) => {
                        setSeed(e.target.value)
                    }} type="text" placeholder="Random"/>
                </div>
            </div>
            <div className="side-bar__box">
                <h6 className="title">{Languages[language].draft_quality}</h6>
                <p>{Languages[language].draft_quality_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('draft')
                }} className={`btn-action style-2 ${template === 'draft' && "active"}`}>25 {rates.currency}</button>
            </div>
            <div className="side-bar__box">
                <h6 className="title">{Languages[language].basic_quality}</h6>
                <p>{Languages[language].basic_quality_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('basic')
                }} className={`btn-action style-2 ${template === 'basic' && "active"}`}>50 {rates.currency}</button>
            </div>

            <div className="side-bar__box">
                <h6 className="title">{Languages[language].standard_quality}</h6>
                <p>{Languages[language].standard_quality_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('standard')
                }} className={`btn-action style-2 ${template === 'standard' && "active"}`}>100 {rates.currency}</button>


            </div>
            <div className="side-bar__box">
                <h6 className="title">{Languages[language].premium_quality}</h6>
                <p>{Languages[language].premium_quality_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('premium')
                }} className={`btn-action style-2 ${template === 'premium' && "active"}`}>200 {rates.currency}</button>
            </div>
            <div className="side-bar__box">
                <h6 className="title">{Languages[language].supreme_quality}</h6>
                <p>{Languages[language].supreme_quality_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('supreme')
                }} className={`btn-action style-2 ${template === 'supreme' && "active"}`}>300 {rates.currency}</button>

            </div>
            <div className="side-bar__box">
                <h6 className="title">{Languages[language].custom}</h6>
                <p>{Languages[language].custom_bottom_text}</p>
                <button onClick={() => {
                    setTemplate('custom')
                }} className={`btn-action style-2 ${template === 'custom' && "active"}`}>Custom
                </button>


            </div>
            <div>
                <div className='price_wrapper' style={{display: 'flex'}}>
                    <h1 style={{fontSize: '80px'}}>{calculatePrice()}
                    </h1> <span style={{marginLeft: '10px'}}/> {rates.currency}

                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    marginTop: '-20px',
                    marginBottom: '10px'
                }}>
                    {calculatePriceInCurrency(calculatePrice())}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;