import img1 from '../images/items/montono-01.png'
import img2 from '../images/items/montono-02.png'
import img3 from '../images/items/montono-03.png'
import img4 from '../images/items/montono-04.png'
import img5 from '../images/items/montono-05.png'
import img6 from '../images/items/montono-06.png'
import img7 from '../images/items/montono-07.png'
import img8 from '../images/items/montono-08.png'
import img9 from '../images/items/montono-09.png'
import img10 from '../images/items/montono-10.png'
import img11 from '../images/items/montono-11.png'
import img12 from '../images/items/montono-12.png'
import img13 from '../images/items/montono-13.png'
import img14 from '../images/items/montono-14.png'

const dataMontono = [
    {
        img: img1,
        title: 'Monteno item 1'
    },
    {
        img: img2,
        title: 'Monteno item 2'
    },
    {
        img: img3,
        title: 'Monteno item 3'
    },
    {
        img: img4,
        title: 'Monteno item 4'
    },
    {
        img: img5,
        title: 'Monteno item 5'
    },
    {
        img: img6,
        title: 'Monteno item 6'
    },
    {
        img: img7,
        title: 'Monteno item 7'
    },
    {
        img: img8,
        title: 'Monteno item 8'
    },
    {
        img: img9,
        title: 'Monteno item 3'
    },
    {
        img: img10,
        title: 'Monteno item 4'
    },
    {
        img: img11,
        title: 'Monteno item 5'
    },
    {
        img: img12,
        title: 'Monteno item 6'
    },
    {
        img: img13,
        title: 'Monteno item 7'
    },
    {
        img: img14,
        title: 'Monteno item 8'
    },
]

export default dataMontono;