import icon1 from '../images/icon/database.png'
import icon2 from '../images/icon/lock.png'
import icon3 from '../images/icon/paymentt.png'
import icon4 from '../images/icon/timestaps.png'

const dataTechnology = [
    {
        icon: icon1,
        title: 'Safe Database',
        desc: 'Aliquam erat volutpat. Fusce sit amet'
    },
    {
        icon: icon2,
        title: 'Safe Database',
        desc: 'Aliquam erat volutpat. Fusce sit amet'
    },
    {
        icon: icon3,
        title: 'verify payment',
        desc: 'Aliquam erat volutpat. Fusce sit amet'
    },
    {
        icon: icon4,
        title: 'Timestamp server',
        desc: 'Aliquam erat volutpat. Fusce sit amet'
    },
]

export default dataTechnology;