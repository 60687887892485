import React, { useState, useContext, useEffect } from "react";
import { Languages } from "../languages/languages";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { DataContext } from "../context/dataContext";
import Sidebar from "../components/sidebar/Sidebar";
import CreateInputs from "../components/layouts/BlogL";
import getAllJobs from "../helper/getAllJob";
import EmptyJobCheck from "../helper/emptyJobCheck";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreatePage = () => {
  const navigate = useNavigate();
  const { user, language } = useContext(DataContext);
  const [createInputsData, setCreateInputsData] = useState({});
  const [template, setTemplate] = useState("draft");
  const [seed, setSeed] = useState("-1");
  const [price, setPrice] = useState(25);
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("");
  const [error, setError] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  let rates = JSON.parse(localStorage.getItem("Rates"));

  useEffect(() => {
    setTimeout(() => {
      setError("");
    }, 4000);
  }, [error]);

  useEffect(() => {
    if (user) {
        getAllJobs(user);
    }
    }, [user]);

  const removeEmptyJobFromLocalStorage = () => {
    let jobs = JSON.parse(localStorage.getItem("jobs"));
    let filteredJobs = jobs.filter((job) => job.status !== "init");
    localStorage.setItem("jobs", JSON.stringify(filteredJobs));
  };
  const sendJobWithPayments = async () => {
    let { last_name, first_name, email, phone, meta, uuid } = user;
    const emptyJob = await EmptyJobCheck(user);
    let settings = {
      ...createInputsData,
      seed: seed,
    };
    let priceData = calculatePriceToSend(price);
    let dataToSend = {
      merchant_id: "artfactorylive",
      save: true,
      job: {
        ...emptyJob,
        config: settings,
        status: "init",
        payment: {
          method: "arca",
          amount: priceData.price,
          currency: priceData.currency,
          user: {
            user_uuid: uuid,
            fname: first_name,
            lname: last_name,
            address: meta.address,
            address2: meta.address2,
            city: meta.city,
            province: meta.state,
            country: meta.country,
            post_code: meta.zip,
            phone: phone,
            email: email,
          },
        },
      },
    };

    if (dataToSend.job.config.prompt.length < 2) {
      setError("Please add at least 3 characters to your masterpiece");
      return null;
    }
  
    await axios
      .post(`https://api.artfactory.live/pay`, dataToSend, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((res) => {
        // console.log("WalletResponse",res)
        removeEmptyJobFromLocalStorage();
        EmptyJobCheck(user);
        window.location.href = res.data.url;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const calculatePriceToSend = (eurPrice) => {
    let currency = rates?.currency;
    if (currency === undefined) return { price: eurPrice, currency: "EUR" };
    switch (currency) {
      case "HUF":
        return {
          price: eurPrice * rates.rates.HUF,
          currency: "HUF",
        };
      case "USD":
        return {
          price: eurPrice * rates.rates.USD,
          currency: "USD",
        };
      case "AUD":
        return {
          price: eurPrice * rates.rates.AUD,
          currency: "AUD",
        };
      case "GBP":
        return {
          price: eurPrice * rates.rates.GBP,
          currency: "GBP",
        };
      case "NZD":
        return {
          price: eurPrice * rates.rates.NZD,
          currency: "NZD",
        };
      case "EUR":
        return {
          price: eurPrice,
          currency: "EUR",
        };
      case "ZAR":
        return {
          price: eurPrice * rates.rates.ZAR,
          currency: "ZAR",
        };
      case "CAD":
        return {
          price: eurPrice * rates.rates.CAD,
          currency: "CAD",
        };
        break;
      default:
        return {
          price: eurPrice,
          currency: "EUR",
        };
    }
  };

  return (
    <div className="page-blog">
      <Header />

      <section className="blog-list">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-md-12">
              <CreateInputs
                template={template}
                callback={(value) => {
                  setCreateInputsData(value);
                }}
              />
            </div>
            <div className="col-xl-3 col-md-12">
              <Sidebar
                dataFromInputs={createInputsData}
                callback={(value) => {
                  setTemplate(value.template);
                  setSeed(value.seed);
                  setPrice(value.price);
                  setCountry(value.country);
                  setCurrency(value.currency);
                }}
              />
            </div>
            <h4
              style={{
                textAlign: "center",
                marginBottom: "20px",
                color: "red",
              }}
            >
              {error}
            </h4>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {!user && (
                // <button onClick={() => {
                //     sendJobToSave()
                // }} className='btn-action style-2'>{Languages[language].save}</button>
                // :
                <button
                  onClick={() => {
                    navigate("/login");
                  }}
                  className="btn-action style-2"
                >
                  {Languages[language].login}
                </button>
              )}
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "15px", display: "flex" }}>
                  <input
                    checked={checkBox}
                    onChange={(e) => {
                      setCheckBox(e.target.checked);
                    }}
                    type="checkbox"
                  />{" "}
                  <p
                    onClick={() => {
                      setCheckBox((x) => !x);
                    }}
                  >
                    I agree to the{" "}
                  </p>
                  <a style={{ marginLeft: "10px" }} href="/terms">
                    {" "}
                    Terms and conditions
                  </a>
                </div>
                {!!user && (
                  <button
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      //function
                      if (checkBox) {
                        sendJobWithPayments();
                      } else {
                        setError("Please agree to the terms and conditions");
                      }
                    }}
                    className="btn-action style-2"
                  >
                    Pay
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default CreatePage;
