import React, {useState, useEffect, useContext} from 'react';
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import Montono2 from '../components/layouts/Montono2';
import Action from '../components/layouts/Action';
import {DataContext} from "../context/dataContext";
import getAllJobs from "../helper/getAllJob";
import {useNavigate} from "react-router-dom";


const Gallery = () => {
    const {user} = useContext(DataContext)
    const navigate = useNavigate();

    // const [thumbsSwiper, setThumbsSwiper] = useState(null);
    let [dataToPass, setDataToPass] = useState()
    useEffect(() => {
        getAllJobs(user).then(res => {
            setDataToPass(res)
        })
    }, [])

    return (
        <div className='collections'>
            <Header/>

            <Montono2 data={dataToPass}/>
            <div style={{display:'flex',justifyContent:'center',width:'100%'}}>
                {!user &&
                    <button className="btn-action style-2" onClick={() => {
                  navigate('/login')
                    }}>Login
                    </button>}
            </div>
            <Action/>
            <Footer/>
        </div>
    );
}

export default Gallery;