import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import DataContextProvider from './context/dataContext'
import LeftBottomWrapper from './components/layouts/LeftBottomWrapper';

ReactDOM.render(
    <DataContextProvider>
        <BrowserRouter>
            <ScrollToTop/>
            <LeftBottomWrapper/>
            <App/>
        </BrowserRouter>
    </DataContextProvider>,
    document.getElementById('root')
);
