import React , { useState ,useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import {DataContext} from "../../context/dataContext";
import './footer.scss';
import {Languages} from "../../languages/languages";

import logo from '../../assets/images/logo.svg';

const Footer = () => {
    const {language} = useContext(DataContext);
    const windowUrl = window.location.hostname;
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };
  
    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };
  
      window.addEventListener("scroll", toggleVisibility);
  
      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>

        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="footer__body">
                      <Link to="/">
                        <img style={{width:'100%'}} src={logo} alt="Brushless" data-aos="fade-down" />
                      </Link>
                        <div style={{textAlign:"center", height:"60px"}}> <img style={{textAlign:"center", height:"60px"}} src="/cc.png" align={"center"}/>
                        </div>

                    </div>
                    <div className="footer_bottom">
                        <p className="fs-16">Copyright © 2022, Creative AI solutions LLC.</p>
                        <ul>
                            <li><Link to="/terms">{Languages[language].terms_and_conditions}</Link></li>
                            <li><Link to="/privacy">{Languages[language].privacy}</Link></li>
                            <li><Link to="/refund">{Languages[language].refund}</Link></li>
                            {/*<li><Link to="/cib_faq">CIB GYIK / FAQ</Link></li>*/}
                            {/*<li><Link to="/cib_info">CIB BANK INFO</Link></li>*/}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
        {
            isVisible && 
            <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
        }</>
        
    );
}

export default Footer;