import React, {useContext, useEffect} from 'react';
import {DataContext} from "../context/dataContext";
import axios from "axios";
import {useNavigate} from "react-router-dom";

const Thanks = (props) => {
    const navigate = useNavigate();
    const {user} = useContext(DataContext)
    const params = window?.location?.search

    console.log(params)

    const paymentStatusCheck = async (jobId) => {
        let reqBody = {
            "merchant_id": "test",
            "job_id": jobId
        }
        await axios.post('https://api.createart.live/payment', reqBody, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        },)
            .then((res) => {
                if (res?.data) {
                    if(res?.data.status !== 'success'){
                        navigate('/gallery')
                    }
                    if (res.data.status === "success") {
                        startJob(jobId)
                        return null;
                    }
                }else{
                    navigate('/gallery')
                }
            })
            .catch((error) => {
                navigate('/gallery')
            });
    }

    const startJob = async (id) => {
        await axios.get(`https://api.artfactory.live/start/${id}`, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        })
            .then((res) => {
                navigate('/gallery')
            })
            .catch((error) => {
                navigate('/gallery')
            });
    }
useEffect(() => {
    if (params) {
        let jobId = params.split("=")[1]
        paymentStatusCheck(jobId)
    }else {
        navigate('/gallery')
    }
}, [])

    return (
        <></>
    );
}

export default Thanks;