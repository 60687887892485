import avt1 from '../images/testimonial/avt-02.png'
import avt2 from '../images/testimonial/avt-03.png'
import avt3 from '../images/testimonial/avt-04.png'

const dataTestimonials = [
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt1,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt2,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt3,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt1,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
    {
        text: 'Keniam, quis nostrud exerci ut aliquip ex ea com modo cons equat. Duis aute irure dolor in reprehen derit in voluptate velit esse cillum dolore eu fu giat nulla parinon proid',
        avt: avt2,
        name: 'Paul Harrison,',
        postion: 'Founder & CEO'
    },
]

export default dataTestimonials;