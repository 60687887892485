// import 'swiper/swiper.min.css';
import React, {useEffect} from 'react';
import {useContext} from "react";
import {DataContext} from "./context/dataContext";
import '../src/assets/icons/font-awesome.css';
import './App.scss';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import AOS from 'aos';
import routes from './pages/index'
import Home03 from "./pages/Home03";

import {Route, Routes} from 'react-router-dom';

function App() {

    const {setLanguage} = useContext(DataContext)
    const CheckLangUrl = () => {
        let url = window.location.pathname
        let path = url.split('/')[1]
        if (path === 'en' || path === 'fr' || path === 'am') {
            setLanguage(path)
            localStorage.setItem('language', path)
            window.location.replace('/')
        }
    }
    CheckLangUrl()


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (

        <Routes>
            <Route path="/[a-z]{2}" element={<Home03/>}/>
            {
                routes.map((data, index) => (
                    <Route exact={true} path={data.path} element={data.component} key={index}/>
                ))
            }
        </Routes>
    );
}

export default App;
