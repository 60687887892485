import React from "react";
import { qnas } from "../components/pop-thingy/helpers/getQnaTranslations";

export const Languages = {
    en: {
        //buttons
        login:'Login',
        register:'Register',
        logout:'Sign out',
        profile:'Profile',
        gallery:'Gallery',
        create:'Create',
        inspiration:'Inspiration',
        make_art:'Make Art',
        my_gallery:'My Gallery',
        generate_picture:'Generate Picture',
        submit:'Submit',
        refresh:'Refresh',
        download:'Download',
        go_to_customize:'Go to Customize',
        save:'Save',
        delete:'Delete',
        go_to_create:'Go to Create',
        terms_and_conditions:'Terms and Conditions',
        privacy_policy:'Privacy Policy',
        //profile
        name:'Name',
        email:'Email',
        password:'Password',
        confirm_password:'Confirm Password',
        phone:'Phone',
        address:'Address',
        city:'City',
        state:'State',
        zip:'Zip',
        country:'Country',
        county:'County',
        //inspiration
        steps:'Steps',
        seed:'Seed',
        width:'Width',
        height:'Height',
        artist:'Artist',
        template:'Template',
        input:'Input',

        //footer action div
        footer_action_div_top_text:'Create Your New Art',
        footer_action_div_bottom_text:'Generate something amazing',
        
        //satisfied pop up thingy
        pop_title: "Not feeling satisfied?",
        pop_text: "Let us solve your problem.",
        pop_button_text: "Click here!",

        pop_qna_close_text: "Close",
        pop_something_went_wrong: "Oops, something went wrong!",
        // qna: {
        //     base: {
        //         question: "What happened?",
        //         answers: {
        //             speed: {
        //               text: "Speed",
        //               redirect: "speed_whats_wrong",
        //             },
        //             payment: {
        //               text: "Payment",
        //             },
        //             quality: {
        //               text: "Quality",
        //             },
        //         },
        //     },


        //     // speed related
        //     speed_whats_wrong: {
        //         question: "What's wront with the speed?",
            
        //         answers: {
        //           speed_too_slow: {
        //             text: "Too slow",
        //             redirect: "speed_process_rerun",
        //           },
        //           speed_not_loading: {
        //             text: "Stuck at the end.",
        //             redirect: "refund",
        //           },
        //           speed_stuck: {
        //             text: "Not working.",
        //           },
        //         },
        //       },
        //       speed_process_rerun: {
        //         question: "Did you re-run the process?",
            
        //         answers: {
        //           speed_too_slow_yes: {
        //             text: "Yes",
        //             redirect: "refund",
        //           },
        //           speed_too_slow_no: {
        //             text: "No",
        //           },
        //         },
        //       },
        //       speed_process_rerun_did_work: {
        //         question: "Did re-running the process work?",
            
        //         answers: {
        //             speed_process_rerun_did_work_yes: {
        //             text: "Yes",
        //           },
        //           speed_process_rerun_did_work_no: {
        //             text: "No",
        //             redirect: "refund",
        //           },
        //         },
        //       },
            
            
        //       //base last refund
        //       refund: {
        //         question: "Sorry to hear that it didn't work.",
            
        //         answers: {
        //           refund_refund: {
        //             text: "I want a refund.",
        //             redirect: "refund",
        //           },
        //           refund_free_token: {
        //             text: "I want a free token.",
        //           },
        //         },
        //       },
        // },
        
        //create page
        
        qna: qnas, // imported from "getAllQnaTranslations", because its 1:1 the same

        pop_refund_title: "Which image made you make this decision?",
        pop_refund_button: "Accept & Send",
        pop_refund_button_disabled: "Select atleast one!",

        pop_redirect_close: "Alright. Hope you can solve it.",
        pop_redirect_redirecting: "Redirecting...",

        positive_top_text:"Tell us about your masterpiece",
        positive_bottom_text:<>Enter some text for the artist to create your image, it works best if you think of your ideas as captions for the result you would like.<br></br></>,
        avoid_top_text:'Is there something you want to avoid',
        avoid_bottom_text:<>You can add things that you don't want to see in your image here. as above it's best to use captions of the items to avoid.</>,
        artist_top_text:'Your artist',
        artist_bottom_text:<>There are currently 3 artists to choose from. These artists will make different decisions each step of the way to create your magic. you can see some examples above; for beginners we suggest artist1</>,
        size_top_text:'What size would you like your works',
        size_bottom_text:'You can select from 64px to 2048px for height or width.',
        high_top_text:'Going BIG?',
        high_bottom_text:'the artists work best around 512px and if going past this point it should be done in a single direction or with the assistance of this high-quality setting that will split the work into 512x512 jobs and merge them.',
        high_input_label:'High Quality',
        steps_top_text:'Steps',
        steps_bottom_text:'each step allows the artist to improve your image, around 20 steps will get you a basic image, 50 or so would be better for higher quality.',
        steps_input_label:'Sampling steps',
        artist_freedom_top_text:'Artist Freedom',
        artist_freedom_bottom_text:<>here you can choose the weight of your input to the piece. A basic setting of around 7 yields results that both represent your ideas and have some freedom of expression. 1 will only contain a hint of your input and 30 will most likly over bake the ideas.
            <br></br> Suggested: 5-15</>,
        artist_freedom_input_label:'CFG scale',
        //create page/quality
        starting_number:'Starting number',
        starting_number_bottom_text:'Optional',
        draft_quality:'Draft quality',
        draft_quality_bottom_text:'Most cost-effective',
        basic_quality:'Basic quality',
        basic_quality_bottom_text:'More refined',
        standard_quality:'Standard quality',
        standard_quality_bottom_text:'Double the size',
        premium_quality:'Premium quality',
        premium_quality_bottom_text:'More refined',
        supreme_quality:'Supreme quality',
        supreme_quality_bottom_text:'Huge',
        custom:'Custom',
        custom_bottom_text:'Go crazy',
        //home page
        home_page_first_slider_top_text:'Make something amazing in minutes',
        home_page_first_slider_bottom_text:'The machine does all the work',
        home_page_second_slider_top_text:'Create pictures almost instantly',
        home_page_second_slider_middle_text:'you supply the concepts for the art and have access to additional advanced options if you want to go crazy',
        home_page_second_slider_bottom_text:'You can download and have full usage rights to your artwork, along with all the information needed to recreate or remix your creation. ',

        join:'Join',
        join_top_text:'Join and create amazing images',
        become:<>Become a Artfactory <br></br> User Now </>,
        register_text:"Register an account with us and get amazing pictures",
        go_to_create_page:'Go to Create Page',
        go_to_create_page_text:'Go to create page and create your own images',
        write_your_magic:'Write your magic',
        write_your_magic_text:'Write your magic and the machine will do the rest',
        wait_for_the_result:'Wait for the result',
        wait_for_the_result_text:'Wait for the results and enjoy your new images',

        FAQ_short_title:'FAQ',
        FAQ_title:'Frequently Asked Questions',
        FAQ:[
            {
                title: 'WHAT IS AN ARTIST?',
                text: <>
                    <p>Each artist is an image generation system.</p>
                    <p>It combines previous ideas including:</p>
                    <ul>
                        <li>● Perception Engines which uses image augmentation and iteratively optimises images against an
                            ensemble of classifiers
                        </li>
                        <br></br>
                        <li>● CLIP guided GAN imagery from Ryan Murdoch and Katherine Crowson as well as modifictions such as
                            CLIPDraw from Kevin Frans
                        </li>
                        <br></br>
                        <li>● Useful ways of navigating latent space from Sampling Generative Networks</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                title: 'WHY DOES IT COST MORE FOR LARGER IMAGES?',
                text: <>
                    <p>Every image is generated frame by frame and this consumes considerable resources.</p>
                    <ul>
                        <li>● Draft takes only a few seconds</li>
                        <li>● Supreme can take 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>During this time we have the latest neural network hardware totally consumed by your artwork.</p>
                    <p>We are able to scale by purchasing more hardware and process more in parallel.</p>
                </>,
            },
            {
                title: 'WHY CANT I HAVE A BIGGER IMAGE?',
                text: 'We are working on a solution to make even larger images, right now we are constrained by the amount of memory we can have in a single artist. We hope to add the addition of super-sampling to increase the size considerably.'
            },
        ],
        TERMS:<>
            <h2>Terms and Conditions</h2>

            <p>By accessing this website we assume you accept these terms and conditions. Do not continue to
                use our site if you do not agree to take all of the terms and conditions stated on this
                page.</p>
            <p>The following terminology applies to these Terms and Conditions, Privacy Statement and
                Disclaimer
                Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company’s terms and conditions. "The Company", "Ourselves",
                "We",
                "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the
                Client
                and ourselves. All terms refer to the offer, acceptance and consideration of payment
                necessary
                to undertake the process of our assistance to the Client in the most appropriate manner for
                the
                express purpose of meeting the Client’s needs in respect of provision of the Company’s
                stated
                services, in accordance with and subject to, prevailing law of Armenia. Any use of the
                above
                terminology or other words in the singular, plural, capitalization and/or he/she or they,
                are
                taken as interchangeable and therefore as referring to same.</p>
            <h4><strong>Cookies</strong></h4>
            <p>We employ the use of cookies. By accessing our site, you agreed to use cookies in agreement
                with the our site's Privacy Policy. </p>
            <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit.
                Cookies are used by our website to enable the functionality of certain areas to make it
                easier
                for people visiting our website. Some of our affiliate/advertising partners may also use
                cookies.</p>
            <h4><strong>License</strong></h4>
            <p>Unless otherwise stated, our site and/or its licensors own the intellectual property rights
                for all material on our site. All intellectual property rights are reserved. You may
                access
                this from our site for your own personal use subjected to restrictions set in these terms
                and
                conditions.</p>
            <p>You must not:</p>
            <ul>
                <li>Republish material from our site</li>
                <li>Sell, rent or sub-license material from our site</li>
                <li>Reproduce, duplicate or copy material from our site</li>
                <li>Redistribute content from our site</li>
            </ul>
            <p>This Agreement shall begin on the date hereof.</p>
            <p>Parts of this website offer an opportunity for users to post and exchange opinions and
                information in certain areas of the website. our site does not filter, edit, publish or
                review Comments prior to their presence on the website. Comments do not reflect the views
                and
                opinions of our site,its agents and/or affiliates. Comments reflect the views and opinions
                of the person who post their views and opinions. To the extent permitted by applicable laws,
                our site shall not be liable for the Comments or for any liability, damages or expenses
                caused and/or suffered as a result of any use of and/or posting of and/or appearance of the
                Comments on this website.</p>
            <p>our site reserves the right to monitor all Comments and to remove any Comments which can be
                considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>
            <p>You warrant and represent that:</p>
            <ul>
                <li>You are entitled to post the Comments on our website and have all necessary licenses and
                    consents to do so;
                </li>
                <li>The Comments do not invade any intellectual property right, including without limitation
                    copyright, patent or trademark of any third party;
                </li>
                <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise
                    unlawful material which is an invasion of privacy
                </li>
                <li>The Comments will not be used to solicit or promote business or custom or present
                    commercial
                    activities or unlawful activity.
                </li>
            </ul>
            <p>You hereby grant our site a non-exclusive license to use, reproduce, edit and authorize
                others to use, reproduce and edit any of your Comments in any and all forms, formats or
                media.</p>
            <h4><strong>Hyperlinking to our Content</strong></h4>
            <p>The following organizations may link to our Website without prior written approval:</p>
            <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>Online directory distributors may link to our Website in the same manner as they
                    hyperlink
                    to the Websites of other listed businesses; and
                </li>
                <li>System wide Accredited Businesses except soliciting non-profit organizations, charity
                    shopping malls, and charity fundraising groups which may not hyperlink to our Web site.
                </li>
            </ul>
            <p>These organizations may link to our home page, to publications or to other Website
                information so
                long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products and/or services; and (c) fits
                within the context of the linking party’s site.</p>
            <p>We may consider and approve other link requests from the following types of
                organizations:</p>
            <ul>
                <li>commonly-known consumer and/or business information sources;</li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
            </ul>
            <p>We will approve link requests from these organizations if we decide that: (a) the link would
                not
                make us look unfavorably to ourselves or to our accredited businesses; (b) the organization
                does
                not have any negative records with us; (c) the benefit to us from the visibility of the
                hyperlink compensates the absence of our site; and (d) the link is in the context of
                general
                resource information.</p>
            <p>These organizations may link to our home page so long as the link: (a) is not in any way
                deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking
                party
                and its products or services; and (c) fits within the context of the linking party’s
                site.</p>
            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking
                to
                our website, you must inform us by sending an e-mail to our site. Please include your
                name,
                your organization name, contact information as well as the URL of your site, a list of any
                URLs
                from which you intend to link to our Website, and a list of the URLs on our site to which
                you
                would like to link. Wait 2-3 weeks for a response.</p>
            <p>Approved organizations may hyperlink to our Website as follows:</p>
            <ul>
                <li>By use of our corporate name; or</li>
                <li>By use of the uniform resource locator being linked to; or</li>
                <li>By use of any other description of our Website being linked to that makes sense within
                    the
                    context and format of content on the linking party’s site.
                </li>
            </ul>
            <p>No use of our site's logo or other artwork will be allowed for linking absent a trademark
                license agreement.</p>
            <h4><strong>iFrames</strong></h4>
            <p>Without prior approval and written permission, you may not create frames around our Webpages
                that
                alter in any way the visual presentation or appearance of our Website.</p>
            <h4><strong>Content Liability</strong></h4>
            <p>We shall not be hold responsible for any content that appears on your Website. You agree to
                protect and defend us against all claims that is rising on your Website. No link(s) should
                appear on any Website that may be interpreted as libelous, obscene or criminal, or which
                infringes, otherwise violates, or advocates the infringement or other violation of, any
                third
                party rights.</p>
            <h4><strong>Your Privacy</strong></h4>
            <p>Please read <a href="/privacy">Privacy Policy</a></p>
            <h4><strong>Reservation of Rights</strong></h4>
            <p>We reserve the right to request that you remove all links or any particular link to our
                Website.
                You approve to immediately remove all links to our Website upon request. We also reserve the
                right to amen these terms and conditions and it’s linking policy at any time. By
                continuously
                linking to our Website, you agree to be bound to and follow these linking terms and
                conditions.</p>
            <h4><strong>Removal of links from our website</strong></h4>
            <p>If you find any link on our Website that is offensive for any reason, you are free to contact
                and
                inform us any moment. We will consider requests to remove links but we are not obligated to
                or
                so or to respond to you directly.</p>
            <p>We do not ensure that the information on this website is correct, we do not warrant its
                completeness or accuracy; nor do we promise to ensure that the website remains available or
                that
                the material on the website is kept up to date.</p>
            <h4><strong>Disclaimer</strong></h4>
            <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties
                and
                conditions relating to our website and the use of this website. Nothing in this disclaimer
                will:</p>
            <ul>
                <li>limit or exclude our or your liability for death or personal injury;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable
                    law;
                    or
                </li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.
                </li>
            </ul>
            <p>The limitations and prohibitions of liability set in this Section and elsewhere in this
                disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities
                arising
                under the disclaimer, including liabilities arising in contract, in tort and for breach of
                statutory duty.</p>
            <p>As long as the website and the information and services on the website are provided free of
                charge, we will not be liable for any loss or damage of any nature.</p>


            <h4><strong>Delivery</strong></h4>
            <p>Upon completing a successful purchase on our site you can choose when to create your
                art at any
                time. After the art is created you can download the output(s) in your account.</p>
            <h4><strong>Cancelation</strong></h4>
            <p>Before you have started your creation but you have completed a successful purchase
                you may cancel your order. Please be sure to be in contact
                with our team by email and we will be happy to assist you.</p>

            <a name="copy"><h4><strong>Copyrighted content</strong></h4></a>
            <p>Unauthorized usage of copyrighted works, including without limitation, Images, pictures,
                logos, texts, and any other visual or written content, may be unlawful and may infringe
                rights of the copyright owner and is prohibited on our website.</p>
            <p>If you are not sure about what constitutes copyrighted works please visit: <a target="_blank"
                                                                                             rel="nofollow"
                                                                                             href="https://ogc.harvard.edu/pages/copyright-and-fair-use">https://ogc.harvard.edu/pages/copyright-and-fair-use</a>
            </p>
            <p>Should you make unauthorized usage of copyrighted works our site reserves the
                right to notify the proper authorities and/or prevent the usage of the software when
                possible and will, in any case, not be liable for any copyright infringements.</p>
        </>,
        refund: "Refund Policy",
        REFUND:<><h1>Refund Policy</h1>

            <p>Thank you for shopping at Creative AI solutions LLC. We value your trust and are committed to providing high-quality products/services. Your satisfaction is our priority, and as such, we have implemented the following refund policy:</p>

            <p>1. Definitions and Key Terms:</p>

            <ul>
                <li>&quot;Creative AI solutions LLC&quot; refers to our company, accessible at Artfactory.live.</li>
                <li>&quot;Goods&quot; refer to the product(s) for sale on our website.</li>
                <li>&quot;Order&quot; means a request by you to purchase Goods from us.</li>
            </ul>

            <p>2. Your Order Cancellation Rights:</p>

            <ul>
                <li>You are entitled to cancel your Order within 14 calendar days without giving any reason for doing so.</li>
                <li>The deadline for cancelling an Order is 14 calendar days from the date on which you received the Goods. In order to exercise your right of cancellation, you must inform us of your decision by means of a clear statement. You can inform us of your decision by:
                    <ul>
                        <li>Email:refunds@artfactory.live.</li>
                    </ul>
                </li>
            </ul>

            <p>3. Refunds:</p>

            <ul>
                <li >We will immediately notify you on the status of your refund after inspecting the cancelation claim.</li>
                <li >If your return is approved, we will initiate a refund to your credit card (or original method of payment).</li>
                <li >You will receive the credit within a certain amount of days, depending on your card issuer&#39;s policies.</li>
            </ul>

            <p>4. Sale Items:</p>

            <ul>
                <li>Sale items can be refunded.&nbsp;</li>
            </ul>

            <p>5. Contact Us:</p>

            <ul>
                <li>If you have any questions about our Returns and Refunds Policy, please contact us:

                    <ul>
                        <li>By email: refunds[at]artfactory.live</li>
                    </ul>
                </li>
            </ul></>,
        privacy: "Privacy Policy",
        PRIVACY:<><h1><span>Privacy Policy</span></h1>
            <p><span>Thank you for choosing to be a part of our community at Creative AI solutions LLC ("we", "us", or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy or our practices with regards to your personal information, please contact us at &lsquo;privacy@artfactory.live&rsquo;</span></p>
            <p><span>This privacy policy governs the privacy policies and practices of our Website, located at artfactory.live. Please read our privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</span></p>
            <ol>
                <li><span> Information We Collect</span></li>
            </ol>
            <p><span>We collect personal information that you voluntarily provide to us when registering at the Website, expressing an interest in obtaining information about us or our products and services, when participating in activities on the Website or otherwise contacting us.</span></p>
            <p><span>The personal information that we collect depends on the context of your interaction with us and the Website, the choices you make and the products and features you use. The personal information we collect may include the following:</span></p>
            <p><span>Personal Information: Categorically speaking, we collect name, address, contact information, etc.</span></p>
            <ol start="2">
                <li><span> How We Use Your Information</span></li>
            </ol>
            <p><span>We use the information we collect or receive for the purposes of billing and verification.</span></p>
            <ol start="3">
                <li><span> Disclosure of Your Personal Information</span></li>
            </ol>
            <p><span>We may share your personal information in the following situations:</span></p>
            <p><span>Compliance with Laws: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).</span></p>
            <p><span>Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</span></p>
            <ol start="4">
                <li><span> Cookies and Similar Technologies</span></li>
            </ol>
            <p><span>We use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.</span></p>
            <p><br /><br /></p>
            <ol start="5">
                <li><span> Security of Your Information</span></li>
            </ol>
            <p><span>We use administrative, technical, and physical security measures to protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.</span></p>
            <ol start="6">
                <li><span> Your Privacy Rights</span></li>
            </ol>
            <p><span>In some regions (like the European Economic Area), you have rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information.</span></p>
            <ol start="7">
                <li><span> Updates to this Policy</span></li>
            </ol>
            <p><span>We may update this privacy policy from time to time.&nbsp;</span></p>
            <ol start="8">
                <li><span> How to Opt Out</span></li>
            </ol>
            <p><span>If you no longer wish to receive correspondence, emails, or other communications from us, you may opt-out by:</span></p>
            <p><span>Noting your preferences at the time you register your account with the Site</span></p>
            <p><span>Logging into your account settings and updating your preferences.</span></p></>
    },
    //France
    fr: {
        //buttons
        login:'Connexion',
        register:'S\'inscrire',
        logout:'Déconnexion',
        profile:'Profil',
        gallery:'Galerie',
        create:'Créer',
        inspiration:'Inspiration',
        make_art:'Faire de l\'art',
        my_gallery:'Ma galerie',
        generate_picture:'Générer une image',
        submit:'Soumettre',
        refresh:'Rafraîchir',
        download:'Télécharger',
        go_to_customize:'Aller à Personnaliser',
        save:'Économiser',
        delete:'Effacer',
        go_to_create:'Allez dans Créer',
        terms_and_conditions:'Termes et conditions',
        privacy_policy:'Politique de confidentialité',
        //profile
        name:'Nom',
        email:'E-mail',
        password:'Passe',
        confirm_password:'Confirmez le mot de passe',
        phone:'Téléphoner',
        address:'Adresse',
        city:'Ville',
        state:'Etat',
        zip:'Zip ',
        country:'Pays',
        county:'Comté',
        //inspiration
        steps:'Pas',
        seed:'Planter',
        width:'Largeur',
        height:'Hauteur',
        artist:'Artiste',
        template:'Modèle',
        input:'Saisir',

        //footer action div
        footer_action_div_top_text:'Créez votre nouvel art',
        footer_action_div_bottom_text:'Générez quelque chose d\'incroyable',

        //satisfied pop up thingy
        pop_title: "Vous ne vous sentez pas satisfait(e) ?",
        pop_text: "Laissez-nous résoudre votre problème.",
        pop_button_text: "Cliquez ici !",
    
        pop_qna_close_text: "Fermer",
        pop_something_went_wrong: "Oups, quelque chose s'est mal passé!",
        qna: {
          base: {
            question: "Qu'est-il arrivé ?",
            answers: {
              speed: {
                text: "Vitesse",
                redirect: "speed_whats_wrong"
              },
              payment: {
                text: "Paiement"
              },
              quality: {
                text: "Qualité"
              }
            }
          },

          speed_whats_wrong: {
            question: "Qu'est-ce qui ne va pas avec la vitesse ?",
            answers: {
              speed_too_slow: {
                text: "Trop lent",
              },
              speed_not_loading: {
                text: "Bloqué à la fin.",
              },
              speed_stuck: {
                text: "Ne fonctionne pas.",
              },
            },
          },

          payment_whats_wrong: { 
            question: "Qu'est-ce qui ne va pas avec le paiement?", 
            answers: {
              payment_still_pending: {
                text: "Déjà payé, mais ça indique toujours en attente...",
              },
              payment_charged_twice: {
                text: "J'ai été débité deux fois.",
              },
            }
          },
          payment_still_pending: { 
            question: "Essayez de le générer à nouveau?",
            answers: {
              payment_still_pending_regen: {
                text: "Oui.",
              },
              payment_still_pending_refund: {
                text: "Non, je veux un remboursement.",
              },
            },
          },

          quality_whats_wrong: { 
            question: "Qu'est-ce qui ne va pas avec la qualité?",
            answers: {
              quality_bad: {
                text: "La qualité de l'image est mauvaise.",
              },
              payment_charged_twice: {
                text: "L'image n'est pas entièrement générée.",
              },
            },
          }, 
          quality_bad: { 
            question: "Essayez de le générer à nouveau?",
            answers: {
              quality_bad_regen: {
                text: "Oui.",
              },
              quality_bad_refund: {
                text: "Non, je veux un remboursement.",
              },
            },
          },

          refund: {
            question: "Désolé d'apprendre que cela n'a pas fonctionné.",
            answers: {
              refund_refund: {
                text: "Je souhaite un remboursement.",
              },
              refund_free_token: {
                text: "Je souhaite un jeton gratuit."
              }
            }
          }
        },

        pop_refund_title: "Quelle image vous a fait prendre cette décision?",
        pop_refund_button: "Accepter et Envoyer",
        pop_refund_button_disabled: "Sélectionnez au moins un!",

        pop_redirect_close: "D'accord. J'espère que vous pourrez le résoudre.",
        pop_redirect_redirecting: "Redirection en cours...",

        //create page
        positive_top_text:"Parlez-nous de votre chef-d'œuvre",
        positive_bottom_text:<>Entrez du texte pour que l'artiste crée votre image, cela fonctionne mieux si vous considérez vos idées comme des légendes pour le résultat que vous souhaitez.<br></br></>,
        avoid_top_text:'Y a-t-il quelque chose que vous voulez éviter',
        avoid_bottom_text:<>Vous pouvez ajouter ici des choses que vous ne voulez pas voir dans votre image. comme ci-dessus, il est préférable d'utiliser les légendes des éléments à éviter.</>,
        artist_top_text:'Votre artiste',
        artist_bottom_text:<>Il y a actuellement 3 artistes parmi lesquels choisir. Ces artistes prendront des décisions différentes à chaque étape du processus pour créer votre magie. vous pouvez voir quelques exemples ci-dessus; pour les débutants, nous suggérons</>,
        size_top_text:'Quelle taille souhaitez-vous pour vos oeuvres',
        size_bottom_text:'Vous pouvez sélectionner de 64px à 2048px pour la hauteur ou la largeur.',
        high_top_text:'Aller GRAND?',
        high_bottom_text:'les artistes travaillent mieux autour de 512px et si vous dépassez ce point, cela devrait être fait dans une seule direction ou avec l\'aide de ce paramètre de haute qualité qui divisera le travail en 512x512 travaux et les fusionnera.',
        high_input_label:'Haute qualité',
        steps_top_text:'Pas',
        steps_bottom_text:'chaque étape permet à l\'artiste d\'améliorer votre image, environ 20 étapes vous permettront d\'obtenir une image de base, une cinquantaine serait préférable pour une meilleure qualité.',
        steps_input_label:'Étapes d\'échantillonnage',
        artist_freedom_top_text:'Liberté de l\'artiste',
        artist_freedom_bottom_text:<>ici, vous pouvez choisir le poids de votre contribution à la pièce. Un réglage de base d'environ 7 donne des résultats qui représentent à la fois vos idées et une certaine liberté d'expression. 1 ne contiendra qu'un soupçon de votre contribution et 30 contiendra très probablement les idées.
            <br></br> Suggéré : 5-15</>,
        artist_freedom_input_label:'CFG scale',
        //create page/quality
        starting_number:'Numéro de départ',
        starting_number_bottom_text:'Vous pouvez éventuellement spécifier un point de départ pour votre art. Cela permettra des ajustements futurs.',
        draft_quality:'Qualité brouillon',
        draft_quality_bottom_text:'Le plus rentable',
        basic_quality:'Qualité de base',
        basic_quality_bottom_text:'Plus raffiné',
        standard_quality:'Qualité standard',
        standard_quality_bottom_text:'Doubler la taille',
        premium_quality:'Qualité supérieure',
        premium_quality_bottom_text:'Plus raffiné',
        supreme_quality:'Qualité suprême',
        supreme_quality_bottom_text:'Énorme',
        custom:'Personnalisé',
        custom_bottom_text:'Devenir fou',
        //home page
        home_page_first_slider_top_text:'Créez quelque chose d\'incroyable en quelques minutes',
        home_page_first_slider_bottom_text:'La machine fait tout le travail',

        home_page_second_slider_top_text:'Créez des images presque instantanément',
        home_page_second_slider_middle_text:'vous fournissez les concepts de l\'art et avez accès à des options avancées supplémentaires si vous voulez devenir fou',
        home_page_second_slider_bottom_text:'Vous pouvez télécharger et disposer de tous les droits d\'utilisation de votre œuvre, ainsi que de toutes les informations nécessaires pour recréer ou remixer votre création. ',

        join:'Rejoindre',
        join_top_text:'Rejoignez et créez des images étonnantes',
        become:<>Devenez un utilisateur d'Artfactory <br></br> maintenant</>,
        register_text:"Enregistrez un compte avec nous et obtenez de superbes photos",
        go_to_create_page:'Aller à Créer une page',
        go_to_create_page_text:'Accédez à la page de création et créez vos propres images',
        write_your_magic:'Écrivez votre magie',
        write_your_magic_text:'Écrivez votre magie et la machine fera le reste',
        wait_for_the_result:'Attendez le résultat',
        wait_for_the_result_text:'Attendez les résultats et profitez de vos nouvelles images',

        FAQ_short_title:'FAQ',
        FAQ_title:'Questions fréquemment posées',
        FAQ:[
            {
                title: 'QU\'EST-CE QU\'UN ARTISTE?',
                text: <>
                    <p>Chaque artiste est un système de génération d'images.</p>
                    <p>Il combine des idées précédentes, notamment:</p>
                    <ul>
                        <li>● Perception Engines qui utilise l'augmentation d'image et optimise de manière itérative les images par rapport à un
                            ensemble de classificateurs
                        </li>
                        <br></br>
                        <li>● Imagerie GAN guidée par CLIP de Ryan Murdoch et Katherine Crowson ainsi que des modifications telles que
                            CLIPDraw de Kevin Frans
                        </li>
                        <br></br>
                        <li>● Moyens utiles de naviguer dans l'espace latent à partir des réseaux génératifs d'échantillonnage</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                title: 'POURQUOI LES IMAGES PLUS GRANDES COÛTENT-ELLES PLUS GRANDES ??',
                text: <>
                    <p>Chaque image est générée image par image et cela consomme des ressources considérables.</p>
                    <ul>
                        <li>● Le brouillon ne prend que quelques secondes</li>
                        <li>● Suprême peut prendre 30 minutes</li>
                        <br></br>
                    </ul>
                    <p>Pendant ce temps, nous avons le dernier matériel de réseau neuronal totalement consommé par vos œuvres d'art.</p>
                    <p>Nous pouvons évoluer en achetant plus de matériel et en traitant davantage en parallèle.</p>
                </>,
            },
            {
                title: 'POURQUOI JE NE PEUX PAS AVOIR UNE PLUS GRANDE IMAGE?',
                text: 'Nous travaillons sur une solution pour faire des images encore plus grandes, en ce moment nous sommes limités par la quantité de mémoire que nous pouvons avoir chez un seul artiste. Nous espérons ajouter l\'ajout de super-échantillonnage pour augmenter considérablement la taille.'
            },
        ],
        TERMS:<>
            <h2>Termes et Conditions</h2>

            <p>En accédant à ce site web, nous supposons que vous acceptez ces termes et conditions. Ne continuez pas à utiliser notre site si vous n'acceptez pas tous les termes et conditions énoncés sur cette page.</p>
            <p>La terminologie suivante s'applique à ces Termes et Conditions, Déclaration de Confidentialité et Avis de Non-responsabilité et tous les Accords : "Client", "Vous" et "Votre" se réfèrent à vous, la personne se connectant sur ce site web et conforme aux conditions de l’entreprise. "L'Entreprise", "Nous-mêmes", "Nous", "Notre" et "On", se réfère à notre Entreprise. "Partie", "Parties", ou "Nous", se réfère à la fois au Client et à nous-mêmes. Tous les termes se réfèrent à l'offre, l'acceptation et la considération du paiement nécessaire pour entreprendre le processus de notre assistance au Client de la manière la plus appropriée, dans le but exprès de répondre aux besoins du Client en matière de fourniture des services déclarés de l'Entreprise, conformément à et sous réserve de, la loi en vigueur de l'Arménie. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, pluriel, majuscules et/ou il/elle ou eux, sont considérés comme interchangeables et donc comme se référant à cela.</p>
            <h4><strong>Cookies</strong></h4>
            <p>Nous utilisons des cookies. En accédant à notre site, vous avez accepté d'utiliser des cookies en accord avec la Politique de Confidentialité de notre site.</p>
            <p>La plupart des sites web interactifs utilisent des cookies pour nous permettre de récupérer les détails de l'utilisateur pour chaque visite. Les cookies sont utilisés par notre site web pour activer la fonctionnalité de certaines zones afin de faciliter la visite des personnes sur notre site web. Certains de nos partenaires affiliés/publicitaires peuvent également utiliser des cookies.</p>
            <h4><strong>Licence</strong></h4>
            <p>Sauf indication contraire, notre site et/ou ses concédants de licence détiennent les droits de propriété intellectuelle pour tout le matériel sur notre site. Tous les droits de propriété intellectuelle sont réservés. Vous pouvez y accéder depuis notre site pour votre utilisation personnelle soumise aux restrictions définies dans ces termes et conditions.</p>
            <p>Vous ne devez pas :</p>
            <ul>
                <li>Republish material from our site</li>
                <li>Vendre, louer ou sous-licencier du matériel depuis notre site</li>
                <li>Reproduire, dupliquer ou copier du matériel de notre site</li>
                <li>Redistribuer du contenu depuis notre site</li>
            </ul>
            <p>Cet accord commencera à la date d'aujourd'hui.</p>

            <h4><strong>Livraison</strong></h4>
            <p>Après avoir effectué un achat réussi sur notre site, vous pouvez choisir quand créer votre art à tout moment. Une fois l'art créé, vous pouvez télécharger les résultats dans votre compte.</p>
            <h4><strong>Annulation</strong></h4>
            <p>Avant que vous n'ayez commencé votre création mais que vous ayez effectué un achat réussi, vous pouvez annuler votre commande. Veuillez vous assurer d'être en contact avec notre équipe par e-mail et nous serons heureux de vous aider.</p>

            <a name="copy"><h4><strong>Contenu protégé par le droit d'auteur</strong></h4></a>
            <p>L'utilisation non autorisée d'œuvres protégées par le droit d'auteur, y compris, sans limitation, images, photos, logos, textes et tout autre contenu visuel ou écrit, peut être illégale et peut enfreindre les droits du titulaire du droit d'auteur et est interdite sur notre site web.</p>
            <p>Si vous n'êtes pas sûr de ce qui constitue des œuvres protégées par le droit d'auteur, veuillez visiter : <a target="_blank" rel="nofollow" href="https://ogc.harvard.edu/pages/copyright-and-fair-use">https://ogc.harvard.edu/pages/copyright-and-fair-use</a></p>
            <p>Si vous faites une utilisation non autorisée d'œuvres protégées par le droit d'auteur, notre site se réserve le droit de notifier les autorités compétentes et/ou d'empêcher l'utilisation du logiciel lorsque cela est possible et ne sera, en aucun cas, responsable de toute violation des droits d'auteur.</p>
        </>
        ,
        refund: "Politique de Remboursement",
        REFUND:<><h1>Politique de Remboursement</h1>

            <p>Merci d'avoir fait vos achats chez Creative AI Solutions LLC. Nous valorisons votre confiance et nous nous engageons à fournir des produits/services de haute qualité. Votre satisfaction est notre priorité, et en tant que tel, nous avons mis en œuvre la politique de remboursement suivante :</p>

            <p>1. Définitions et Termes Clés :</p>

            <ul>
                <li>&quot;Creative AI Solutions LLC&quot; fait référence à notre entreprise, accessible sur Artfactory.live.</li>
                <li>&quot;Biens&quot; fait référence aux produit(s) en vente sur notre site web.</li>
                <li>&quot;Commande&quot; signifie une demande de votre part pour acheter des Biens chez nous.</li>
            </ul>

            <p>2. Vos Droits d'Annulation de Commande :</p>

            <ul>
                <li>Vous avez le droit d'annuler votre Commande dans les 14 jours calendaires sans donner de raison pour ce faire.</li>
                <li>Le délai pour annuler une Commande est de 14 jours calendaires à compter de la date à laquelle vous avez reçu les Biens. Pour exercer votre droit d'annulation, vous devez nous informer de votre décision par une déclaration claire. Vous pouvez nous informer de votre décision par :
                    <ul>
                        <li>Email : refunds&#64;artfactory.live</li>
                    </ul>
                </li>
            </ul>

            <p>3. Remboursements :</p>

            <ul>
                <li>Nous vous notifierons immédiatement du statut de votre remboursement après avoir inspecté la demande d'annulation.</li>
                <li>Si votre retour est approuvé, nous initierons un remboursement sur votre carte de crédit (ou méthode originale de paiement).</li>
                <li>Vous recevrez le crédit dans un certain nombre de jours, en fonction des politiques de votre émetteur de carte.</li>
            </ul>

            <p>4. Articles Soldés :</p>

            <ul>
                <li>Les articles soldés peuvent être remboursés.&nbsp;</li>
            </ul>

            <p>5. Contactez-Nous :</p>

            <ul>
                <li>Si vous avez des questions concernant notre Politique de Retours et Remboursements, veuillez nous contacter :
                    <ul>
                        <li>Par email : refunds&#64;artfactory.live</li>
                    </ul>
                </li>
            </ul></>,
        privacy: "Politique de Confidentialité",
        PRIVACY:<>  <h1><span>Politique de Confidentialité</span></h1>
            <p><span>Merci de choisir de faire partie de notre communauté chez Creative AI Solutions LLC ("nous", "notre", ou "nos"). Nous sommes engagés à protéger vos informations personnelles et votre droit à la confidentialité. Si vous avez des questions ou des préoccupations concernant notre politique ou nos pratiques en matière de traitement de vos informations personnelles, veuillez nous contacter à &lsquo;privacy@artfactory.live&rsquo;</span></p>
            <p><span>Cette politique de confidentialité régit les politiques et pratiques de confidentialité de notre site Web, situé à artfactory.live. Veuillez lire attentivement notre politique de confidentialité car elle vous aidera à prendre des décisions informées concernant le partage de vos informations personnelles avec nous.</span></p>
            <ol>
                <li><span>Informations que nous collectons</span></li>
            </ol>
            <p><span>Nous collectons des informations personnelles que vous nous fournissez volontairement lorsque vous vous inscrivez sur le Site Web, exprimez un intérêt pour obtenir des informations sur nous ou nos produits et services, lorsque vous participez à des activités sur le Site Web ou lorsque vous nous contactez autrement.</span></p></>
    },
    //Armenian
    am: {
        //buttons
        login:'Մուտք գործել',
        register:'Գրանցվել',
        logout:'Դուրս գալ',
        profile:'Անձնագիր',
        gallery:'Պատկերասրահ',
        create:'Ստեղծել',
        inspiration:'Ոգեշնչում',
        make_art:'Արվեստ արեք',
        my_gallery:'Իմ պատկերասրահը',
        generate_picture:'Ստեղծեք նկար',
        submit:'Ներկայացնել',
        refresh:'Թարմացնել',
        download:'Բեռնել',
        go_to_customize:'Գնացեք Անհատականացնել',
        save:'Պահպանել',
        delete:'Ջնջել',
        go_to_create:'Գնալ դեպի Ստեղծել',
        terms_and_conditions:'Ընդհանուր դրույթներր եւ պայմանները',
        privacy_policy:'Գաղտնիության քաղաքականություն',
        //profile
        name:'Անուն',
        email:'Էլ',
        password:'Գաղտնաբառ',
        confirm_password:'Հաստատել գաղտնաբառը',
        phone:'Հեռախոս',
        address:'Հասցե',
        city:'Քաղաք',
        state:'Պետություն',
        zip:'Zip',
        country:'Երկիր',
        county:'կոմսություն',
        //inspiration
        steps:'Քայլեր',
        seed:'Սերմ',
        width:'Լայնությունը',
        height:'Բարձրություն',
        artist:'Նկարիչ',
        template:'Կաղապար',
        input:'Մուտքագրում',

        //footer action div
        footer_action_div_top_text:'Ստեղծիր քո նոր արվեստը',
        footer_action_div_bottom_text:'Ստեղծեք զարմանալի բան',

        //satisfied pop up thingy
        pop_title: "Չեք համարվում բարձրացնելու?",
        pop_text: "Թույլատրել մեզ լուծել ձեր խնդիրը։",
        pop_button_text: "Սեղմեք այստեղ!",

        pop_qna_close_text: "Փակել",
        pop_something_went_wrong: "Ուփես, ինչ-որ բան այլևս վախենալ է!",
        qna: {
          base: {
            question: "Ինչ է ենթակա երևում?",
            answers: {
              speed: {
                text: "Արագություն",
                redirect: "speed_whats_wrong"
              },
              payment: {
                text: "Վճարում"
              },
              quality: {
                text: "Ընդացիկություն"
              }
            }
          },
        
          speed_whats_wrong: {
            question: "Ի՞նչ է անքին արագության հետ։",
            answers: {
              speed_too_slow: {
                text: "Ավելի դանդաղ",
              },
              speed_not_loading: {
                text: "Անջատված վերջ",
              },
              speed_stuck: {
                text: "Աշխատանքը դադարեց",
              },
            },
          },
        
          payment_whats_wrong: { 
            question: "Ի՞նչ է անքին վճարումում։", 
            answers: {
              payment_still_pending: {
                text: "Նախքան վճարումը ավարտվել է, բայց դեռանում է սպասարկումը...",
              },
              payment_charged_twice: {
                text: "Ես երկու անգամ վճարվել եմ։",
              },
            }
          },
          payment_still_pending: { 
            question: "Փորձեք վերագեներացնել՝",
            answers: {
              payment_still_pending_regen: {
                text: "Այո.",
              },
              payment_still_pending_refund: {
                text: "Ոչ, ուզում եմ վերադարձը։",
              },
            },
          },
        

          quality_whats_wrong: { 
            question: "Ի՞նչ է անքին ընդացիկության հետ։",
            answers: {
              quality_bad: {
                text: "Պատրաստանից է վերանայված պատկերը։",
              },
              payment_charged_twice: {
                text: "Պատկերը չի լրիվ գեներացվում։",
              },
            },
          }, 
          quality_bad: { 
            question: "Փորձեք վերագեներացնել՝",
            answers: {
              quality_bad_regen: {
                text: "Այո։",
              },
              quality_bad_refund: {
                text: "Ոչ, ցանկանում եմ վերադարձը։",
              },
            },
          },


          refund: {
            question: "Ներողությանը, որ այն չի բաշխատելու մասին հասկացավեցիք։",
            answers: {
              refund_refund: {
                text: "Ես ուզում եմ վերադարձը։",
              },
              refund_free_token: {
                text: "Ես ուզում եմ անվճար թոկեն։",
              }
            }
          }
        },

        pop_refund_title: "Ի՞նչ պատճառով կրել ձեզ այս որոշման համար:",
        pop_refund_button: "Ընդունել և Ուղարկել",
        pop_refund_button_disabled: "Ընտրեք առներ։",
          
        pop_redirect_close: "Համաձայնք՝ յուրացեք դրանք։",
        pop_redirect_redirecting: "Վերահղում է...",

        //create page
        positive_top_text:"Պատմիր քո գլուխգործոցի մասին",
        positive_bottom_text:<>Մուտքագրեք որոշ տեքստ, որպեսզի նկարիչը ստեղծի ձեր պատկերը, այն լավագույնս աշխատում է, եթե ձեր գաղափարները համարում եք վերնագրեր այն արդյունքի համար, որը ցանկանում եք:<br></br></>,
        avoid_top_text:'Կա՞ ինչ-որ բան, որից ուզում եք խուսափել',
        avoid_bottom_text:<>Այստեղ կարող եք ավելացնել բաներ, որոնք չեք ցանկանում տեսնել ձեր պատկերում: ինչպես վերևում, ավելի լավ է խուսափել իրերի վերնագրերից:</>,
        artist_top_text:'Ձեր նկարիչը',
        artist_bottom_text:<>Ներկայումս կա 3 արտիստ, որոնցից կարելի է ընտրել: Այս նկարիչները տարբեր որոշումներ կկայացնեն ձեր կախարդանքը ստեղծելու ճանապարհին յուրաքանչյուր քայլում: վերևում կարող եք տեսնել մի քանի օրինակ. սկսնակների համար մենք առաջարկում ենք</>,
        size_top_text:'Ինչ չափսի կցանկանայիք ձեր աշխատանքները',
        size_bottom_text:'Բարձրության կամ լայնության համար կարող եք ընտրել 64px-ից մինչև 2048px:',
        high_top_text:'ՄԵԾ գնալով?',
        high_bottom_text:'նկարիչները լավագույնս աշխատում են 512px-ի սահմաններում, և եթե այս կետն անցնի, դա պետք է արվի մեկ ուղղությամբ կամ այս բարձրորակ պարամետրի օգնությամբ, որը կբաժանի աշխատանքը 512x512 աշխատատեղերի և կմիավորի դրանք:',
        high_input_label:'Բարձրորակ',
        steps_top_text:'Քայլեր',
        steps_bottom_text:'Յուրաքանչյուր քայլ թույլ է տալիս նկարչին բարելավել ձեր պատկերը, մոտ 20 քայլը ձեզ կտա հիմնական պատկեր, 50 կամ ավելի լավ կլիներ ավելի բարձր որակի համար:',
        steps_input_label:'Նմուշառման քայլեր',
        artist_freedom_top_text:'Արվեստագետի ազատություն',
        artist_freedom_bottom_text:<>այստեղ դուք կարող եք ընտրել ձեր մուտքի քաշը կտորի համար: Շուրջ 7-ի հիմնական պարամետրը տալիս է արդյունքներ, որոնք և՛ ներկայացնում են ձեր գաղափարները, և՛ ունեն արտահայտվելու որոշակի ազատություն: 1-ը միայն կպարունակի ձեր ներդրման ակնարկը, իսկ 30-ը, ամենայն հավանականությամբ, կխթանի գաղափարները.
            <br></br> Առաջարկվող՝ 5-15</>,
        artist_freedom_input_label:'CFG սանդղակ',
        //create page/quality
        starting_number:'Մեկնարկային համարը',
        starting_number_bottom_text:'Դուք կարող եք կամայականորեն նշել ձեր արվեստի մեկնարկային վայրը: Սա թույլ կտա հետագա ճշգրտումներ կատարել:',
        draft_quality:'Նախագծի որակը',
        draft_quality_bottom_text:'Առավել ծախսարդյունավետ',
        basic_quality:'Հիմնական որակ',
        basic_quality_bottom_text:'Ավելի նուրբ',
        standard_quality:'Ստանդարտ որակ',
        standard_quality_bottom_text:'Կրկնակի չափը',
        premium_quality:'Պրեմիում որակ',
        premium_quality_bottom_text:'Ավելի նուրբ',
        supreme_quality:'Բարձր որակ',
        supreme_quality_bottom_text:'Հսկայական',
        custom:'Պատվերով',
        custom_bottom_text:'Խենթանալ',
        //home page
        home_page_first_slider_top_text:'Հրաշալի բան պատրաստեք րոպեների ընթացքում',
        home_page_first_slider_bottom_text:'Մեքենան կատարում է բոլոր աշխատանքները',

        home_page_second_slider_top_text:'Ստեղծեք նկարներ գրեթե ակնթարթորեն',
        home_page_second_slider_middle_text:'դուք տրամադրում եք արվեստի գաղափարները և մուտք ունեք լրացուցիչ առաջադեմ տարբերակներ, եթե ցանկանում եք խենթանալ',
        home_page_second_slider_bottom_text:'Դուք կարող եք ներբեռնել և ունենալ ձեր ստեղծագործության լիարժեք օգտագործման իրավունքներ, ինչպես նաև ձեր ստեղծագործությունը վերստեղծելու կամ վերամիքսելու համար անհրաժեշտ բոլոր տեղեկությունները',

        join:'Միանալ',
        join_top_text:'Միացեք և ստեղծեք զարմանալի պատկերներ',
        become:<>Դարձեք Artfactory <br></br> օգտվող հիմա </>,
        register_text:'Գրանցեք հաշիվ մեզ մոտ և ստացեք զարմանալի նկարներ',
        go_to_create_page:'Գնալ դեպի Ստեղծել էջ',
        go_to_create_page_text:'Գնացեք ստեղծել էջ և ստեղծեք ձեր սեփական պատկերները',
        write_your_magic:'Գրեք ձեր կախարդանքը',
        write_your_magic_text:'Գրեք ձեր կախարդանքը, և մեքենան կանի մնացածը',
        wait_for_the_result:'Սպասեք արդյունքին',
        wait_for_the_result_text:'Սպասեք արդյունքներին և վայելեք ձեր նոր պատկերները',


        FAQ_short_title:'ՀՏՀ',
        FAQ_title:'Հաճախակի տրվող հարցեր',
        FAQ:[
            {
                title: 'Ի՞ՆՉ Է ԱՐՏԻՍՏԸ:',
                text: <>
                    <p>Յուրաքանչյուր նկարիչ պատկերի ստեղծման համակարգ է:</p>
                    <p>Այն համատեղում է նախկին գաղափարները, այդ թվում:</p>
                    <ul>
                        <li>● Ընկալման շարժիչներ, որոնք օգտագործում են պատկերի մեծացում և կրկնվող կերպով օպտիմիզացնում են պատկերները ի դեմ
                            դասակարգիչների անսամբլ
                        </li>
                        <br></br>
                        <li>● Ryan Murdoch-ի և Katherine Crowson-ի կողմից ղեկավարվող GAN պատկերները, ինչպես նաև փոփոխություններ, ինչպիսիք են CLIP-ը.
                            CLIPDraw Քևին Ֆրենսից
                        </li>
                        <br></br>
                        <li>● Նմուշառման գեներատիվ ցանցերից թաքնված տարածությունը նավարկելու օգտակար եղանակներ</li>
                        <br></br>
                    </ul>
                </>,
                show: 'true'
            },
            {
                title: 'ԻՆՉՈՒ Է ԱՎԵԼԻ ԱՐԺԱՆՈՒՄ ԱՎԵԼԻ ՄԵԾ ՊԱՏԿԵՐՆԵՐԻ ՀԱՄԱՐ?',
                text: <>
                    <p>Յուրաքանչյուր պատկեր ստեղծվում է կադր առ կադր, և դա զգալի ռեսուրսներ է ծախսում:</p>
                    <ul>
                        <li>● Սևագիրը տևում է ընդամենը մի քանի վայրկյան</li>
                        <li>● Supreme-ը կարող է տևել 30 րոպե</li>
                        <br></br>
                    </ul>
                    <p>Այս ընթացքում մենք ունենք նեյրոնային ցանցի վերջին ապարատը, որն ամբողջությամբ սպառվում է ձեր արվեստի գործերով:</p>
                    <p>Մենք կարողանում ենք մասշտաբներ ձեռք բերել՝ ավելի շատ սարքավորում ձեռք բերելով և զուգահեռաբար ավելի շատ մշակել:</p>
                </>,
            },
            {
                title: 'ԻՆՉՈՒ ՉԵՄ ԿԱՐՈՂ ՈՒՆԵՄ ԱՎԵԼԻ ՄԵԾ ՊԱՏԿԵՐ:',
                text: 'Մենք աշխատում ենք լուծման վրա՝ ավելի մեծ պատկերներ ստեղծելու համար, հենց հիմա մենք սահմանափակված ենք հիշողության քանակով, որը կարող ենք ունենալ մեկ նկարչի մեջ: Մենք հուսով ենք ավելացնել սուպեր-նմուշառման հավելումը չափը զգալիորեն մեծացնելու համար:'
            },
        ],
        TERMS:<>
            <h2>Պայմաններ և դրույթներ</h2>

            <p>Մուտք գործելով այս կայք՝ մենք ենթադրում ենք, որ դուք ընդունում եք այս դրույթներն ու պայմանները: Մի շարունակեք
                օգտագործեք մեր կայքը, եթե չեք համաձայնում ընդունել սրա վերաբերյալ նշված բոլոր պայմաններն ու պայմանները
                էջ.</p>
            <p>Հետևյալ տերմինաբանությունը կիրառվում է սույն Պայմանների և դրույթների, Գաղտնիության հայտարարության և
                Հրաժարում պատասխանատվությունից
                Ծանուցում և բոլոր պայմանագրերը. «Հաճախորդը», «Դուք» և «Ձեր»-ը վերաբերում է ձեզ, անձը մուտք է գործում այս
                կայքը և համապատասխանում է Ընկերության պայմաններին: «Ընկերությունը», «Մենք ինքներս»,
                «Մենք»,
                «Մեր»-ը և «Մենք»-ը վերաբերում է մեր ընկերությանը: «Կուսակցություն», «Կուսակցություններ» կամ «Մենք» վերաբերում է երկուսին էլ
                Հաճախորդ
                և ինքներս մեզ։ Բոլոր պայմանները վերաբերում են առաջարկին, ընդունմանը և վճարման քննարկմանը
                անհրաժեշտ
                ձեռնարկել Հաճախորդին մեր աջակցության գործընթացը ամենահարմար ձևով
                որ
                Ընկերության տրամադրման հետ կապված Հաճախորդի կարիքները բավարարելու հստակ նպատակ
                հայտարարել է
                ծառայություններ՝ համաձայն և ենթակա Հայաստանի գործող օրենսդրության: -ի ցանկացած օգտագործում
                վերևում
                տերմինաբանություն կամ այլ բառեր եզակի, հոգնակի, մեծատառով և/կամ նա կամ դրանք,
                են
                ընդունված է որպես փոխարինելի և, հետևաբար, վերաբերում է նույնին:</p>
            <h4><strong>Թխուկներ</strong></h4>
            <p>Մենք օգտագործում ենք թխուկների օգտագործումը: Մուտք գործելով մեր կայք՝ դուք համաձայնեցիք օգտագործել թխուկները
                մեր կայքի Գաղտնիության քաղաքականության հետ: </p>
            <p>Ինտերակտիվ կայքերից շատերն օգտագործում են թխուկներ՝ մեզ թույլ տալու յուրաքանչյուր այցելության համար օգտատիրոջ տվյալները առբերելու համար:
                Cookie ֆայլերը օգտագործվում են մեր կայքի կողմից՝ որոշակի տարածքների ֆունկցիոնալությունը հնարավոր դարձնելու համար
                ավելի հեշտ
                մեր կայք այցելող մարդկանց համար: Մեր որոշ գործընկերներ/գովազդային գործընկերներ կարող են նաև օգտագործել
                թխուկներ:</p>
            <h4><strong>Լիցենզիա</strong></h4>
            <p>Եթե այլ բան նշված չէ, մեր կայքը և/կամ դրա արտոնագրողները տիրապետում են մտավոր սեփականության իրավունքներին
                մեր կայքի բոլոր նյութերի համար: Մտավոր սեփականության բոլոր իրավունքները պաշտպանված են: Դու կարող ես
                մուտք
                սա մեր կայքից՝ ձեր անձնական օգտագործման համար, որը ենթակա է սույն պայմաններով սահմանված սահմանափակումների
                և
                պայմանները:</p>
            <p>Դուք չպետք է՝</p>
            <ul>
                <li>Վերահրատարակեք նյութը մեր կայքից</li>
                <li>Վաճառել, վարձել կամ ենթալիցենզավորել նյութեր մեր կայքից</li>
                <li>Վերարտադրեք, կրկնօրինակեք կամ պատճենեք նյութը մեր կայքից</li>
                <li>Վերաբաշխեք բովանդակությունը մեր կայքից</li>
            </ul>
            <p>Սույն Համաձայնագիրը կմեկնարկի սույն թվականի ամսաթվից:</p>
            <p>Այս կայքի մասերը հնարավորություն են տալիս օգտատերերին հրապարակել և փոխանակել կարծիքներ և
                տեղեկատվություն կայքի որոշակի հատվածներում: մեր կայքը չի զտում, խմբագրում, հրապարակում կամ
                վերանայել մեկնաբանությունները՝ նախքան դրանց ներկայությունը կայքում: Մեկնաբանությունները չեն արտացոլում տեսակետները
                և
                մեր կայքի, նրա գործակալների և/կամ մասնաճյուղերի կարծիքները: Մեկնաբանությունները արտացոլում են տեսակետներն ու կարծիքները
                այն անձի, ով հրապարակում է իր տեսակետներն ու կարծիքները: Այն չափով, որը թույլատրվում է կիրառելի օրենսդրությամբ,
                Մեր կայքը պատասխանատվություն չի կրում մեկնաբանությունների կամ որևէ պատասխանատվության, վնասների կամ ծախսերի համար
                առաջացել և/կամ տուժել է որևէ օգտագործման և/կամ տեղադրման և/կամ տեսքի հետևանքով
                Մեկնաբանություններ այս կայքում:</p>
            <p>մեր կայքը իրավունք է վերապահում վերահսկել բոլոր մեկնաբանությունները և հեռացնել ցանկացած մեկնաբանություն, որը կարող է լինել
                համարվում է անպատշաճ, վիրավորական կամ առաջացնում է սույն Պայմանների և դրույթների խախտում:</p>
            <p>Դուք երաշխավորում և ներկայացնում եք, որ.</p>
            <ul>
                <li>Դուք իրավունք ունեք տեղադրել մեկնաբանությունները մեր կայքում և ունեք բոլոր անհրաժեշտ լիցենզիաները և
                    համաձայնում է դա անել;
                </li>
                <li>Մեկնաբանությունները չեն ներխուժում մտավոր սեփականության որևէ իրավունք, ներառյալ առանց սահմանափակման
                    ցանկացած երրորդ կողմի հեղինակային իրավունք, արտոնագիր կամ ապրանքային նշան.
                </li>
                <li>Մեկնաբանությունները չեն պարունակում զրպարտչական, զրպարտչական, վիրավորական, անպարկեշտ կամ այլ կերպ
                    անօրինական նյութ, որը ներխուժում է գաղտնիություն
                </li>
                <li>Մեկնաբանությունները չեն օգտագործվի բիզնեսի կամ սովորույթի կամ ներկայացման համար պահանջելու կամ խթանելու համար
                    կոմերցիոն
                    գործունեություն կամ անօրինական գործունեություն:
                </li>
            </ul>
            <p>Դուք սույնով մեր կայքին տրամադրում եք ոչ բացառիկ արտոնագիր՝ օգտագործելու, վերարտադրելու, խմբագրելու և թույլատրելու համար
                ուրիշներին՝ օգտագործելու, վերարտադրելու և խմբագրելու ձեր մեկնաբանությունները ցանկացած ձևով, ձևաչափով կամլրատվամիջոցներ:</p>
            <h4><strong>Հղում դեպի մեր բովանդակությունը</strong></h4>
            <p>Հետևյալ կազմակերպությունները կարող են կապվել մեր կայքին առանց նախնական գրավոր հաստատման.</p>
            <ul>
                <li>Կառավարական գործակալություններ;</li>
                <li>Որոնման համակարգեր;</li>
                <li>Լրատվական կազմակերպություններ;</li>
                <li>Առցանց գրացուցակի դիստրիբյուտորները կարող են կապել մեր Կայքին նույն ձևով, ինչ իրենք
                    հիպերհղում
                    ցուցակված այլ ձեռնարկությունների կայքերին. և
                </li>
                <li>Համակարգի լայնածավալ հավատարմագրված ձեռնարկություններ, բացառությամբ շահույթ չհետապնդող կազմակերպությունների, բարեգործության
                    առևտրի կենտրոններ և բարեգործական դրամահավաք խմբեր, որոնք կարող են չհղվել մեր վեբ կայքին:
                </li>
            </ul>
            <p>Այս կազմակերպությունները կարող են հղում կատարել մեր գլխավոր էջին, հրապարակումներին կամ այլ Կայքերին
                տեղեկատվություն, այսպես
                քանի դեռ հղումը՝ (ա) ոչ մի կերպ խաբուսիկ չէ. (բ) կեղծ կերպով չի ենթադրում հովանավորություն,
                կապող կողմի և նրա արտադրանքի և/կամ ծառայությունների հաստատումը կամ հաստատումը. և (գ) համապատասխանում է
                կապող կողմի կայքի համատեքստում:</p>
            <p>Մենք կարող ենք դիտարկել և հաստատել կապի այլ հարցումներ հետևյալ տեսակներից
                կազմակերպություններ՝</p>
            <ul>
                <li>սպառողների և/կամ բիզնեսի տեղեկատվության հանրահայտ աղբյուրներ;</li>
                <li>dot.com համայնքի կայքեր;</li>
                <li>ասոցիացիաներ կամ բարեգործական կազմակերպություններ ներկայացնող այլ խմբեր;</li>
                <li>առցանց գրացուցակի դիստրիբյուտորներ;</li>
                <li>ինտերնետային պորտալներ;</li>
                <li>հաշվապահական, իրավաբանական և խորհրդատվական ընկերություններ; և</li>
                <li>կրթական հաստատություններ և առևտրային միավորումներ:</li>
            </ul>
            <p>Մենք կհաստատենք այս կազմակերպություններից հղման հարցումները, եթե որոշենք, որ
                ոչ
                ստիպեք մեզ անբարենպաստ նայենք մեր կամ մեր հավատարմագրված ձեռնարկություններին. բ) կազմակերպությունը
                անում է
                մեզ մոտ բացասական գրառումներ չունենալ; գ) օգուտը մեզ համար տեսանելիությունից
                հիպերհղումը փոխհատուցում է մեր կայքի բացակայությունը. և (դ) հղումը գտնվում է համատեքստում
                գեներալ
                ռեսուրսների մասին տեղեկություններ:</p>
            <p>Այս կազմակերպությունները կարող են կապել մեր գլխավոր էջին, քանի դեռ հղումը՝ (ա) ոչ մի կերպ չէ
                խաբուսիկ; (բ) կեղծ կերպով չի ենթադրում կապի հովանավորություն, հաստատում կամ հաստատում
                կուսակցություն
                և դրա արտադրանքը կամ ծառայությունները. և (գ) տեղավորվում է կապող կողմի համատեքստում
                կայք:</p>
            <p>Եթե դուք վերը նշված 2-րդ պարբերությունում թվարկված կազմակերպություններից մեկն եք և հետաքրքրված եք կապով
                դեպի
                մեր կայքը, դուք պետք է տեղեկացնեք մեզ՝ էլեկտրոնային նամակ ուղարկելով մեր կայք: Խնդրում ենք ներառել ձեր
                Անուն,
                ձեր կազմակերպության անունը, կոնտակտային տվյալները, ինչպես նաև ձեր կայքի URL-ը, ցանկացածի ցանկը
                URL-ներ
                որտեղից դուք մտադիր եք կապել մեր Կայքին, և մեր կայքի URL-ների ցանկը, որոնց
                դու
                կցանկանայի կապել. Սպասեք 2-3 շաբաթ պատասխանի համար:</p>
            <p>Հաստատված կազմակերպությունները կարող են գերհղում կատարել մեր կայքին հետևյալ կերպ.</p>
            <ul>
                <li>Օգտագործելով մեր կորպորատիվ անվանումը; կամ</li>
                <li>Օգտագործելով միատեսակ ռեսուրսների որոնիչ, որը կապված է. կամ</li>
                <li>Մեր վեբկայքի ցանկացած այլ նկարագրության օգտագործումը դրա հետ կապված լինելն իմաստ ունի
                    որ
                    կապող կողմի կայքում բովանդակության համատեքստը և ձևաչափը:
                </li>
            </ul>
            <p>Մեր կայքի տարբերանշանի կամ այլ արվեստի գործի օգտագործումը չի թույլատրվում ապրանքանիշի բացակայության դեպքում կապակցելու համար
                լիցենզիայի պայմանագիր:</p>
            <h4><strong>iFrames</strong></h4>
            <p>Առանց նախնական հաստատման և գրավոր թույլտվության, դուք չեք կարող ստեղծել շրջանակներ մեր վեբէջերի շուրջ
                որ
                ցանկացած կերպ փոխել մեր կայքի տեսողական ներկայացումը կամ տեսքը:</p>
            <h4><strong>Բովանդակության պարտավորություն</strong></h4>
            <p>Մենք պատասխանատվություն չենք կրի ձեր կայքում հայտնված ցանկացած բովանդակության համար: Դուք համաձայն եք
                պաշտպանեք և պաշտպանեք մեզ բոլոր այն պնդումներից, որոնք բարձրանում են ձեր կայքում: Ոչ մի հղում (ներ) չպետք է լինի
                հայտնվել ցանկացած Կայքում, որը կարող է մեկնաբանվել որպես զրպարտություն, անպարկեշտ կամ հանցավոր, կամ որը
                խախտում է, այլ կերպ խախտում կամ պաշտպանում է որևէ մեկի խախտումը կամ այլ խախտումը
                երրորդ
                կուսակցության իրավունքները:</p>
        </>,
        refund: "Փոխհատուցման քաղաքականություն",
        REFUND:<><h1>Վճարի վերադարձի քաղաքականություն</h1>

            <p>Շնորհակալություն Creative AI solutions LLC-ից գնումներ կատարելու համար: Մենք գնահատում ենք ձեր վստահությունը և պարտավորվում ենք ապահովել բարձրորակ ապրանքներ/ծառայություններ: Ձեր գոհունակությունը մեր առաջնահերթությունն է, և որպես այդպիսին, մենք իրականացրել ենք գումարի վերադարձի հետևյալ քաղաքականությունը.</p>

            <p>1. Սահմանումներ և հիմնական տերմիններ.</p>

            <ul>
                <li>&laquo;Creative AI Solutions LLC&raquo; վերաբերում է մեր ընկերությանը, որը հասանելի է Artfactory.live-ում:</li>
                <li>&laquo;Ապրանքներ&raquo; տեսեք մեր կայքում վաճառվող ապրանք(ներ)ը:</li>
                <li>&laquo;Պատվիրել&raquo; նշանակում է ձեր կողմից ապրանքներ մեզանից գնելու խնդրանք:</li>
            </ul>

            <p>2. Ձեր պատվերի չեղարկման իրավունքները՝</p>

            <ul>
                <li>Դուք իրավունք ունեք չեղարկել Ձեր Պատվերը 14 օրացուցային օրվա ընթացքում՝ առանց դրա համար որևէ պատճառ նշելու:</li>
                <li>Պատվերը չեղարկելու վերջնաժամկետը 14 օրացուցային օր է՝ սկսած ապրանքը ստանալու օրվանից: Ձեր չեղարկման իրավունքից օգտվելու համար դուք պետք է մեզ տեղեկացնեք ձեր որոշման մասին հստակ հայտարարության միջոցով: Դուք կարող եք մեզ տեղեկացնել ձեր որոշման մասին՝
                    <ul>
                        <li>Email:refunds@artfactory.live:</li>
                    </ul>
                </li>
            </ul>

            <p>3. Գումարի վերադարձ՝</p>

            <ul>
                <li >Մենք անմիջապես կտեղեկացնենք ձեզ ձեր գումարի վերադարձի կարգավիճակի մասին չեղարկման հայցը ստուգելուց հետո:</li>
                <li >Եթե ձեր վերադարձը հաստատվի, մենք կսկսենք վերադարձնել ձեր վարկային քարտին (կամ վճարման սկզբնական եղանակին):</li>
                <li >Դուք վարկը կստանաք որոշակի օրերի ընթացքում՝ կախված ձեր քարտ թողարկողի քաղաքականությունից:</li>
            </ul>

            <p>4. Վաճառքի ապրանքներ՝</p>

            <ul>
                <li>Վաճառքի ապրանքները կարող են հետ վերադարձվել:&nbsp;</li>
            </ul>

            <p>5. Կապվեք մեզ հետ՝</p>

            <ul>
                <li>Եթե հարցեր ունեք մեր վերադարձի և վերադարձման քաղաքականության վերաբերյալ, խնդրում ենք կապվել մեզ հետ՝

                    <ul>
                        <li>Էլեկտրոնային փոստով՝ refunds[at]artfactory.live</li>
                    </ul>
                </li>
            </ul></>,
        privacy: "Գաղտնիության քաղաքականություն",
        PRIVACY:<><h1><span>Գաղտնիության քաղաքականություն</span></h1>
            <p><span>Շնորհակալություն, որ ընտրեցիք լինել մեր համայնքի մի մասը Creative AI solutions LLC-ում («մենք», «մենք» կամ «մեր»): Մենք պարտավորվում ենք պաշտպանել ձեր անձնական տվյալները և ձեր գաղտնիության իրավունքը: Եթե ունեք հարցեր կամ մտահոգություններ մեր քաղաքականության կամ մեր գործելակերպի վերաբերյալ՝ կապված ձեր անձնական տվյալների հետ, խնդրում ենք կապվել մեզ հետ &lsquo;privacy@artfactory.live&rsquo;</span></p> հասցեով:
            <p><span>Գաղտնիության այս քաղաքականությունը կարգավորում է մեր կայքի գաղտնիության քաղաքականությունը և գործելակերպը, որը գտնվում է artfactory.live-ում: Խնդրում ենք ուշադիր կարդալ մեր գաղտնիության քաղաքականությունը, քանի որ այն կօգնի ձեզ տեղեկացված որոշումներ կայացնել ձեր անձնական տվյալները մեզ հետ կիսելու վերաբերյալ:</span></p>
            <ol>
                <li><span> Տեղեկություն, որը մենք հավաքում ենք</span></li>
            </ol>
            <p><span>Մենք հավաքում ենք անձնական տեղեկությունները, որոնք դուք կամավոր տրամադրում եք մեզ Կայքում գրանցվելիս, մեր կամ մեր ապրանքների և ծառայությունների մասին տեղեկություններ ստանալու, Կայքում գործունեությանը մասնակցելու կամ մեզ հետ այլ կերպ կապվելու ժամանակ հետաքրքրություն հայտնելիս: </span></p>
            <p><span>Անձնական տեղեկատվությունը, որը մենք հավաքում ենք, կախված է մեզ և Կայքի հետ ձեր փոխգործակցության համատեքստից, ձեր կատարած ընտրություններից և ձեր օգտագործած ապրանքներից ու հնարավորություններից: Մեր հավաքած անձնական տվյալները կարող են ներառել հետևյալը՝</span></p>
            <p><span>Անձնական տվյալներ. կտրականապես, մենք հավաքում ենք անունը, հասցեն, կոնտակտային տվյալները և այլն:</span></p>
            <ol start="2">
                <li><span> Ինչպես ենք մենք օգտագործում ձեր տվյալները</span></li>
            </ol>
            <p><span>Մենք օգտագործում ենք այն տեղեկությունները, որոնք հավաքում կամ ստանում ենք հաշիվների և ստուգման նպատակով:</span></p>
            <ol start="3">
                <li><span> Ձեր անձնական տվյալների բացահայտում</span></li>
            </ol>
            <p><span>Մենք կարող ենք կիսվել ձեր անձնական տվյալներով հետևյալ իրավիճակներում.</span></p>
            <p><span>Համապատասխանություն օրենքներին. մենք կարող ենք բացահայտել ձեր տեղեկությունները, երբ մեզանից օրինական պահանջվում է դա անել՝ կիրառելի օրենսդրությանը, կառավարական պահանջներին, դատական գործընթացին, դատարանի որոշմանը կամ իրավական գործընթացին համապատասխանելու համար, օրինակ՝ ի պատասխան դատարանի որոշում կամ ծանուցագիր (այդ թվում՝ ի պատասխան պետական մարմինների՝ ազգային անվտանգության կամ իրավապահ մարմինների պահանջները բավարարելու համար):</span></p>
            <p><span>Կենսական շահ. գործունեությունը կամ որպես ապացույց դատավարության մեջ, որում մենք ներգրավված ենք:</span></p>
            <ol start="4">
                <li><span> Թխուկներ և նմանատիպ տեխնոլոգիաներ</span></li>
            </ol>
            <p><span>Տեղեկություն մուտք գործելու կամ պահելու համար մենք օգտագործում ենք թխուկներ և նմանատիպ հետագծման տեխնոլոգիաներ (օրինակ՝ վեբ փարոսներ և պիքսելներ):</span></p>
            <p><br /><br /></p>
            <ol start="5">
                <li><span> Ձեր տեղեկատվության անվտանգությունը</span></li>
            </ol>
            <p><span>Մենք օգտագործում ենք վարչական, տեխնիկական և ֆիզիկական անվտանգության միջոցներ՝ ձեր անձնական տվյալները պաշտպանելու համար: Թեև մենք ողջամիտ քայլեր ենք ձեռնարկել ձեր տրամադրած անձնական տեղեկությունները պաշտպանելու համար, խնդրում ենք տեղյակ լինել, որ չնայած մեր ջանքերին, անվտանգության ոչ մի միջոց կատարյալ կամ անթափանցելի չէ, և տվյալների փոխանցման ոչ մի մեթոդ չի կարող երաշխավորվել որևէ գաղտնալսման կամ այլ տեսակի չարաշահումից: </span></p>
            <ol start="6">
                <li><span> Ձեր գաղտնիության իրավունքները</span></li>
            </ol>
            <p><span>Որոշ տարածաշրջաններում (օրինակ՝ Եվրոպական տնտեսական տարածքում), դուք իրավունքներ ունեք տվյալների պաշտպանության գործող օրենքների համաձայն: Դրանք կարող են ներառել իրավունքը (i) մուտքի թույլտվություն պահանջելու և ձեր անձնական տեղեկատվության պատճենը ստանալու, (ii) ուղղում կամ ջնջում խնդրելու իրավունքը. (iii) սահմանափակել ձեր անձնական տեղեկատվության մշակումը. և (iv) եթե կիրառելի է, տվյալների տեղափոխելիության համար: Որոշակի հանգամանքներում դուք կարող եք նաև իրավունք ունենալ առարկելու ձեր անձնական տվյալների մշակմանը:</span></p>
            <ol start="7">
                <li><span> Այս քաղաքականության թարմացումներ</span></li>
            </ol>
            <p><span>Մենք կարող ենք ժամանակ առ ժամանակ թարմացնել այս գաղտնիության քաղաքականությունը:&nbsp;</span></p>
            <ol start="8">
                <li><span> Ինչպես հրաժարվել</span></li>
            </ol>
            <p><span>Եթե այլևս չեք ցանկանում մեզնից նամակագրություն, էլ. նամակներ կամ այլ հաղորդակցություններ ստանալ, կարող եք հրաժարվել հետևյալ կերպ՝</span></p>
            <p><span>Նշելով ձեր նախապատվությունները այն պահին, երբ գրանցում եք ձեր հաշիվը Կայքում</span></p>
            <p><span>Մուտք գործեք ձեր հաշվի կարգավորումներ և թարմացրեք ձեր նախապատվությունները:</span></p>
        </>
    },

}