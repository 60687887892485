import axios from "axios";
import getAllJobs from "./getAllJob";

const EmptyJobCheck = (user) => {
  const createEmptyJob = async () => {
    return await axios
      .patch(
        `https://api.artfactory.live/job`,
        {},
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then((res) => {
        //merge all jobs with new job and save to localstorage
        getAllJobs(user, [res.data]);
        return res.data;
      })
      .catch((error) => {});
  };

  let localStorageJobs = localStorage.getItem("jobs");
  if (localStorageJobs === null) return createEmptyJob();
  let emptyJob = JSON.parse(localStorageJobs).find(
    (job) => job.status === "init"
  );
  if (emptyJob === undefined) {
    return createEmptyJob();
  } else {
    return emptyJob;
  }
};
export default EmptyJobCheck;
