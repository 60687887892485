import React, {useEffect, useState} from 'react'
import RangeSlider from "../RangeSlider/RangeSlider";
import {SamplingMethods, OptionsPackage} from "./DataForOptions";


export default function SettingsForImageToImage({callback, Pamount}) {

    let rates = JSON.parse(localStorage.getItem('Rates'))
    const steps = Array.from(Array(150).keys()).map((i) => i + 1)
    const dimensions = [64, 128, 192, 256, 320, 384, 448, 512, 576, 640, 704, 768, 832, 896, 960, 1024, 1088, 1152, 1216, 1280, 1344, 1408, 1472, 1536, 1600, 1664, 1728, 1792, 1856, 1920, 1984, 2048,]
    const CFG = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30,]
    const denoisingRate = [0.1, 0.11, 0.12, 0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24, 0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36, 0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48, 0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6, 0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72, 0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84, 0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96, 0.97, 0.98, 0.99, 1]
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [samplingSteps, setSamplingSteps] = useState(20);
    const [Denoising, setDenoising] = useState(0.75);
    const [width, setWidth] = useState(512);
    const [height, setHeight] = useState(512);
    const [CFG_Scale, setCFG_Scale] = useState(7);
    const [onePointFive, setOnePointFive] = useState(false)
    const [highQuality, setHighQuality] = useState(false)
    const [defaultChange, setDefaultChange] = useState('draft')
    const currencyHUF = false

    let DefaultOptions = {
        draft: {
            samplingSteps: 20,
            width: 512,
            height: 512,
            CFG: 7,
        },
        basic: {
            samplingSteps: 40,
            width: 512,
            height: 512,
            CFG: 7,
        },
        standard: {
            samplingSteps: 20,
            width: 1024,
            height: 1024,
            CFG: 7,
        },
        premium: {
            samplingSteps: 40,
            width: 1024,
            height: 1024,
            CFG: 7,

        },
        supreme: {
            samplingSteps: 50,
            width: 1600,
            height: 1600,
            CFG: 7,
        },
        custom: {
            samplingSteps: 20,
            width: 512,
            height: 512,
            CFG: 7,
        },
    }


    const calculatePrice = (calculateToHuf) => {
        let HUF = rates.rates.HUF ?? 420
        let amount = Math.round(Math.max(
            25,
            (width || 512) *
            (height || 512) *
            (samplingSteps || 20) /
            209715.2
        )) * (onePointFive ? 1.05 : 1)

        return calculateToHuf ? Math.round(amount * HUF) : amount
    }


    useEffect(() => {
        Pamount({currency: currencyHUF ? 'HUF' : 'USD', amount: calculatePrice(currencyHUF)})
        callback(
            {
                "init_images": [
                    "string"
                ],
                "seed": -1,
                "steps": samplingSteps,
                "mask": "string",
                "cfg_scale": CFG_Scale,
                "width": width,
                "height": height,
                "prompt": prompt,
                "negative_prompt": negativePrompt,
                "restore_faces": onePointFive,
                "type": "img2img",
                "enable_hr": highQuality,
                "firstphase_width": 0,
                "firstphase_height": 0,
                "denoising_strength": Denoising,

            }
        )
    }, [prompt, negativePrompt, samplingSteps, width, height, CFG_Scale, onePointFive, Denoising]);

    useEffect(() => {
        setSamplingSteps(DefaultOptions[defaultChange].samplingSteps)
        setWidth(DefaultOptions[defaultChange].width)
        setHeight(DefaultOptions[defaultChange].height)
        setCFG_Scale(DefaultOptions[defaultChange].CFG)
    }, [defaultChange])

    return (
        <>
            <div style={{display: 'flex', justifyContent: 'center', marginTop:'20px'}}>
                <div className="side-bar__box" style={{paddingBottom: '12px', paddingTop: '8px'}}>
                    <h6 className="title">Write Your Magic | You can separate your text with | for multiple
                        inputs. </h6>
                    <div className="input-group" style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{width: '100%'}} className="form-outline">
                            <input onChange={(event) => {
                                setPrompt(event.target.value)
                            }} type="search" id="form1" className="form-control" placeholder="Positive Prompt"/>

                        </div>
                        <div style={{width: '100%', marginTop: '30px'}} className="form-outline">
                            <input onChange={(event) => {
                                setNegativePrompt(event.target.value)
                            }} type="search" id="form1" className="form-control" placeholder="Negative Prompt"/>

                        </div>


                    </div>
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
                <div className='form_inputs'>
                    <div className="side-bar__box">
                        <h6 className="title">Options</h6>
                        <ul className="tag">
                            {
                                OptionsPackage.map((data, index) => (
                                    <li className={defaultChange === data.name.toLowerCase() ? "active" : null}
                                        onClick={() => {
                                            setDefaultChange(data.name.toLowerCase())
                                        }} key={index}><span className="fs-18">{data.name}</span></li>
                                ))
                            }
                        </ul>
                    </div>
                </div>

            </div>


            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', flexWrap: 'wrap'}}>

                <div className="input_boxes_wrapper">
                    <div className='input_wrapper'>
                        <span>Denoising rate</span>
                        <RangeSlider
                            steps={denoisingRate}
                            setDefault={denoisingRate.findIndex((i) => i === 0.75)}
                            indexOfDefault={0}
                            callback={(x) => {
                                setDenoising(x.value)
                            }}/>
                    </div>
                    <div className='input_wrapper'>
                       <span>Sampling steps</span>
                        <RangeSlider
                            steps={steps}
                            setDefault={steps.findIndex((i) => i === DefaultOptions[defaultChange].samplingSteps)}
                            indexOfDefault={4}
                            callback={(x) => {
                                setSamplingSteps(x.value)
                            }}/>
                    </div>
                    <div className='input_wrapper'>
                       <span>Width</span>
                        <RangeSlider
                            steps={dimensions}
                            indexOfDefault={7}
                            setDefault={dimensions.findIndex((i) => i === DefaultOptions[defaultChange].width)}
                            callback={(x) => {
                                setWidth(x.value)
                            }}/></div>

                <div className='input_wrapper'>
                    <span>Height</span>
                    <RangeSlider
                        steps={dimensions}
                        indexOfDefault={7}
                        setDefault={dimensions.findIndex((i) => i === DefaultOptions[defaultChange].height)}
                        callback={(x) => {
                            setHeight(x.value)
                        }}/>
                </div>
                <div className='input_wrapper'>
                    <span>CFG scale</span>
                    <RangeSlider
                        steps={CFG}
                        indexOfDefault={12}
                        setDefault={CFG.findIndex((i) => i === DefaultOptions[defaultChange].CFG)}
                        callback={(x) => {
                            setCFG_Scale(x.value)
                        }}/>
                </div>
                </div>
                    <div className="price_wrapper" style={{display: 'flex'}}>
                        <h1>{calculatePrice(currencyHUF)}
                        </h1> {rates.currency}
                        -------(

                        {calculatePrice(true)}
                        {rates.country === 'HU' ? 'HUF' : 'USD'}
                        )
                    </div>

                </div>
            </>
            )
            }

