export const SamplingMethods = [
    {
        name: 'Euler a',
    },
    {
        name: 'Euler ',
    },
    {
        name: 'DPM2 a',
    },


]
export const OptionsPackage = [
    {name: "Custom"},
    {name: "DRAFT"},
    {name: "Basic"},
    {name: "Standard"},
    {name: "Premium"},
    {name: "Supreme"},
]