const dataRoadMap = [
    {
        title: 'Idea Generation',
        text: 'Oficia dese runt molit anim iesod est labo met conse ctetur adipi',
        time: 'February 01, 2022',
        class: 'top'
    },
    {
        title: 'Research',
        text: 'Oficia dese runt molit anim iesod est labo met conse ctetur adipi',
        time: 'February 01, 2022',
        class: 'bottom'
    },
    {
        title: 'Development',
        text: 'Oficia dese runt molit anim iesod est labo met conse ctetur adipi',
        time: 'February 01, 2022',
        class: 'top'
    },
    {
        title: 'Result',
        text: 'Oficia dese runt molit anim iesod est labo met conse ctetur adipi',
        time: 'February 01, 2022',
        class: 'bottom'
    },
    {
        title: 'Idea Generation',
        text: 'Oficia dese runt molit anim iesod est labo met conse ctetur adipi',
        time: 'February 01, 2022',
        class: 'top'
    },
]

export default dataRoadMap;