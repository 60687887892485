import React, {useEffect, useState, useContext} from 'react';
import {Link, useLocation} from "react-router-dom";
import menus from "../../pages/menu";
import {DataContext} from "../../context/dataContext";
import './header.scss';
import logo from '../../assets/images/logo.svg';
import ButtonOne from '../button/ButtonOne';
import France from '../../assets/icons/languages/france.png'
import Eng from '../../assets/icons/languages/united-kingdom.png'
import Armenia from '../../assets/icons/languages/armenia.png'
import OutsideClickHandler from 'react-outside-click-handler';
import {Languages} from '../../languages/languages';

const Header = () => {
    const {user, setUser, language, setLanguage} = useContext(DataContext);
    const {pathname} = useLocation();
    const [languageActive, setLanguageActive] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [hoveredLanguage, setHoveredLanguage] = useState('');

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 100);
        });
        return () => {
            setScroll({});
        }
    }, []);


    const [menuActive, setMenuActive] = useState(null);

    const handleMenuActive = () => {
        setMenuActive(!menuActive);
    };


    const [activeIndex, setActiveIndex] = useState(null);
    const handleDropdown = index => {
        setActiveIndex(index);
    };
    const singOut = () => {
        localStorage.removeItem("userCredentials")
        localStorage.removeItem("jobs")
        localStorage.removeItem("imageToCustomize")
        setUser(false)
    }
    let languagesOptions = {
        "fr": {img: France, name: "Français"},
        "en": {img: Eng, name: "English"},
        "am": {img: Armenia, name: "Հայերեն"}
    }


    return (
        <header id="header_main" className={`header js-header ${scroll ? 'is-fixed' : ''}`}>
            <div className="container">
                <div className="row" style={{marginRight: '0'}}>
                    <div className="col-12">
                        <div className="header__body d-flex justify-content-between">
                            <div className="header__logo">
                                <Link to="/">
                                    <img id="site-logo" style={{width: '100%', height: '100%', maxWidth: '250px'}}
                                         src={logo}
                                         alt="artfactory"/>
                                </Link>
                            </div>

                            <div className="header__right">

                                <nav id="main-nav" className={`main-nav ${menuActive ? 'active' : ''}`}>
                                    <ul id="menu-primary-menu" className="menu">
                                        {
                                            menus.map((data, index) => {
                                                const {auth, id} = data
                                                if (user !== false && (id === 8 || id === 9)) return null
                                                return (!auth || (!!user && auth)) &&
                                                    <li key={index} onClick={() => handleDropdown(index)}
                                                        className={`menu-item ${data.namesub ? 'menu-item-has-children' : ''} ${activeIndex === index ? 'active' : ''} ${pathname === data.links ? 'active' : ''} `}>
                                                        <a href={data.links}>{data.name[language]}</a>
                                                        {
                                                            data.namesub &&
                                                            <ul className="sub-menu">
                                                                {
                                                                    data.namesub.map((submenu) => (
                                                                        <li key={submenu.id} className={
                                                                            pathname === submenu.links
                                                                                ? "menu-item current-item"
                                                                                : "menu-item"
                                                                        }><Link to={submenu.links}>{submenu.sub}</Link>
                                                                        </li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        }

                                                    </li>
                                            })
                                        }
                                    </ul>
                                </nav>
                                <div className='languages'>
                                    {languageActive && <div className='language_name'>{hoveredLanguage} </div>}
                                    <div className='language_wrapper'>
                                        <img
                                            onMouseEnter={() => {
                                                setHoveredLanguage(languagesOptions[language].name)
                                            }}
                                            onMouseLeave={() => {
                                                setHoveredLanguage('')
                                            }}
                                            onClick={() => {
                                                setLanguageActive(x => !x)
                                            }} src={languagesOptions[language].img}/>
                                    </div>
                                    <div className={`language_options${languageActive ? '-active' : ''}`}>

                                        {
                                            Object.keys(languagesOptions).map((key, index) => {
                                                if (key === language) return null
                                                return <OutsideClickHandler key={index}  onOutsideClick={() => {
                                                    setLanguageActive(false)
                                                }}>
                                                    <div className='language_wrapper'>
                                                        <img
                                                            onMouseEnter={() => {
                                                                setHoveredLanguage(languagesOptions[key].name)
                                                            }}
                                                            onMouseLeave={() => {
                                                                setHoveredLanguage('')
                                                            }}
                                                            onClick={() => {
                                                                setLanguage(key)
                                                                localStorage.setItem('language', key)
                                                            }} src={languagesOptions[key].img}/></div>
                                                </OutsideClickHandler>
                                            })
                                        }

                                    </div>

                                </div>
                                <div className="button">

                                    {!!user &&
                                        <div style={{marginLeft: '15px'}}><ButtonOne text={Languages[language].logout}
                                                                                     path={'/login'}
                                                                                     _callback={singOut}/></div>}

                                </div>

                                <div className={`mobile-button ${menuActive ? 'active' : ''}`}
                                     onClick={handleMenuActive}><span></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;