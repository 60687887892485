import React , {  useContext} from 'react';
import { Link } from 'react-router-dom'
import {Languages} from "../../languages/languages";

import {DataContext} from "../../context/dataContext";

const Action = () => {
    const {user, language} = useContext(DataContext)


    return (
        <section className="tf-section action">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="action__body" data-aos="fade-up">
                            <div className="block-text">
                                <h3 className="mb-13">{Languages[language].footer_action_div_top_text}</h3>
                                <p className="fs-21 mb-7">{Languages[language].footer_action_div_bottom_text}</p>
                            </div>
                            {!!user ? <Link to="/create" className="btn-action style-2">{Languages[language].make_art}</Link> : <Link to="/login" className="btn-action style-2">{Languages[language].login}</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Action;