import React, { useContext, useState } from "react";

import { DataContext } from "../../context/dataContext";
import Base from "./sections/Base";
import Qna from "./sections/Qna";
import RefundProcess from "./sections/RefundProcess";
import Close from "./sections/Close";

import "./_pop-thingy.scss";

const PopThingy = ({ initShown = true }) => {
  const [shown, setShown] = useState(initShown);
  const [popPage, setPopPage] = useState("base");

  const { user } = useContext(DataContext);

  if (!shown || !user) return null;

  const PopManager = () => {
    switch (popPage.page) {
      case "qna":
        return <Qna setShown={setShown} setPopPage={setPopPage} />;
      case "refund":
        return <RefundProcess setShown={setShown} setPopPage={setPopPage} popPage={popPage} />;
      case "close_redirect":
          return <Close setShown={setShown} popPage={popPage} />;
  
      default:
        return <Base setPopPage={setPopPage} setShown={setShown} />;
    }
  };

  return (
    <div
      className={`base d-flex flex-column align-items-center ${
        ["qna", "refund"].includes(popPage.page) && "qna-visible"
      }`}
    >
      <PopManager />
    </div>
  );
};

export default PopThingy;
