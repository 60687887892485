import React, {useContext} from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, Scrollbar, A11y} from 'swiper';
import {DataContext} from "../../context/dataContext";

const tData = {
    fr: [{
        "text": "Qualité brouillon",
        "name": "25",
        "position": "Le plus économique",
        "description": "Idéal pour les petits projets et les travaux à petit budget."
    }, {
        "text": "Qualité basique",
        "position": "Plus raffiné",
        "name": "50",
        "description": "Un équilibre entre le coût et la qualité."
    }, {
        "text": "Qualité standard",
        "position": "Double la taille",
        "name": "100",
        "description": "Travail de haute qualité adapté à la plupart des besoins."
    }, {
        "text": "Qualité premium",
        "position": "Plus raffiné",
        "name": "200",
        "description": "Détails et précision supérieurs pour les projets importants."
    }, {
        "text": "Qualité suprême",
        "position": "Énorme",
        "name": "300",
        "description": "Qualité professionnelle de premier ordre pour ceux qui veulent le meilleur."
    }, {
        "text": "Personnalisé",
        "position": "Laissez-vous aller",
        "name": "5-1000",
        "description": "Option entièrement personnalisable pour répondre à des exigences et complexités spécifiques."
    }], en: [{
        "text": "Draft quality",
        "name": "25",
        "position": "Most cost-effective",
        "description": "Ideal for small projects and low-budget works."
    }, {
        "text": "Basic quality",
        "position": "More refined",
        "name": "50",
        "description": "A balance between cost and quality."
    }, {
        "text": "Standard quality",
        "position": "Double the size",
        "name": "100",
        "description": "High-quality work suited for most purposes."
    }, {
        "text": "Premium quality",
        "position": "More refined",
        "name": "200",
        "description": "Superior detail and precision for important projects."
    }, {
        "text": "Supreme quality",
        "position": "Huge",
        "name": "300",
        "description": "Top-notch, professional-grade quality for those wanting the best."
    }, {
        "text": "Custom",
        "position": "Go crazy",
        "name": "5-1000",
        "description": "Fully customizable option to meet specific demands and complexities."
    }], am: [
        {
            "text": "Սևագիր որակ",
            "name": "25",
            "position": "Ամենաարժեքային",
            "description": "Իդեալական է փոքր նախագծերի և ցածր բյուջեի աշխատանքների համար։"
        },
        {
            "text": "Հիմնական որակ",
            "position": "Ավելի մաքուր",
            "name": "50",
            "description": "Գնային եւ որակի միջակայքում հարմարավետ է։"
        },
        {
            "text": "Ստանդարտ որակ",
            "position": "Կրկնապատկված չափ",
            "name": "100",
            "description": "Բարձր որակի աշխատանք, հարմար է շատերի համար։"
        },
        {
            "text": "Պրեմիում որակ",
            "position": "Ավելի մաքուր",
            "name": "200",
            "description": "Բարձրագույն ճշգրտությունը և ճկունությունը կարևոր նախագծերի համար։"
        },
        {
            "text": "Գերագույն որակ",
            "position": "Մեծ",
            "name": "300",
            "description": "Առաջատար, մասնագիտական մակարդակի որակ, նրանց համար, ովքեր ցանկանում են լավագույնը։"
        },
        {
            "text": "Ընտելիքային",
            "position": "Գնացեք խելացի",
            "name": "5-1000",
            "description": "Լրիվ հարմարեցված տարբերակը հատուկ պահանջների և բարդությունների բավարարելու համար։"
        }
    ]
}


const Testimonials2 = (props) => {
    let rates = JSON.parse(localStorage.getItem('Rates')) ?? {currency: 'USD', rate: 1};
    const {language} = useContext(DataContext)
    const data = props?.data ?? tData[language] ?? tData['en'];

    return (<section className="tf-section testimonial" id='testimonial' style={{paddingTop: "4em"}}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="block-text center">
                            <h1 className="heading-bg" data-aos="fade-in" data-aos-duration="1000"><span>Pricing</span>
                            </h1>
                            <h5 className="sub-title mb-10" data-aos="fade-up" data-aos-duration="1000">Pricing</h5>
                            <h3 className="title mb-28" data-aos="fade-up" data-aos-duration="1000">Our Packages</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="testimonial__content style-2">
                            <Swiper
                                modules={[Pagination, Scrollbar, A11y]}
                                spaceBetween={30}
                                pagination={{clickable: true}}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    }, 767: {
                                        slidesPerView: 2,
                                    }, 991: {
                                        slidesPerView: 3,
                                    },
                                }}
                                scrollbar={{draggable: true}}
                            >
                                {data.map((data, index) => (<SwiperSlide key={index}>
                                        <div className="box-testimonial">
                                            {/*<img className="quote" src={icon.leftquote2} alt="Monteno" />*/}
                                            <p className="text"><b>{data.text}</b></p>
                                            <p> {data.description}</p>
                                            <div className="info">


                                                {/*<img src={data.avt} alt="Monteno" />*/}
                                                <div>
                                                    <h6>{data.name} {rates.currency}</h6>
                                                    <p className="fs-16" style={{color:'#777'}}>{data.position}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>))}
                            </Swiper>

                        </div>
                    </div>
                </div>
            </div>
        </section>);
}

export default Testimonials2;